import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import UseLanguage, { LanguageContext } from './contexts/useLanguage';
import UseAuth, { AuthContext } from './hooks/useAuth';
import Dashboard from './pages/dashboard';
import ChangePassForm from './pages/login/components/ChangePassForm';
import ForgotForm from './pages/login/components/ForgotForm';
import LoginForm from './pages/login/components/LoginForm';
import LoginLayout from './pages/login/LoginLayout';
import PrivateRouter from './routes/PrivateRouter';
import PublicRouter from './routes/PublicRouter';
import CompanyProvider from './pages/companyLayout/CompanyProvider';
import CompanyPage from './pages/companyLayout/companies';
import Location from './pages/companyLayout/locations';
import EmployeesPage from './pages/companyLayout/employees';
import LocationDetail from './pages/companyLayout/locationDetail';
import FormEmployee from './pages/companyLayout/employees/components/FormEmployee';
import AccountPage from './pages/account';
import SupportLayout from './pages/supportLayout';
import EmployeeProvider from './pages/companyLayout/employees/EmployeeProvider';
import RegionProvider from './pages/companyLayout/RegionProvider';
import LocationProvider from './pages/companyLayout/locations/LocationProvider';
import LoadingCircular from './components/LoadingCircular';
import ReportDashboard from './pages/reports/reportDashboard';
import ReportPage from './pages/reports';
import UpsertPermit from './pages/permitsLayout/addEditPermit';
import ListPermit from './pages/permitsLayout';
import FilterPermitProvider from './pages/permitsLayout/PermitProvider';
import UpLoadPermit from './pages/permitsLayout/uploadPermits';
import Notify from './components/toast/ToastMessage';
import { appConfig } from './config/AppConfig';
import { Role } from './models/permission/Role';
import HoldingPage from './pages/holding';
import FrequentlyAskedQuestions from './pages/faqs';
import FeedbackPage from './pages/feedback';
import DashboardReportProvider from './pages/dashboardLayout/DashboardReportProvider';
import TenantList from './pages/tenantList';

function App() {
    const authData = UseAuth();
    const language = UseLanguage();
    const { isEnableManageCompany, isEnableManagePermits } = appConfig.flagFeatures;
    const { account } = authData;

    return (
        <LanguageContext.Provider value={language}>
            <AuthContext.Provider value={authData}>
                <Notify />
                {authData.isLoading ? (
                    <LoadingCircular />
                ) : (
                    <Routes>
                        {authData.isAuth === false ? (
                            <Route element={<PublicRouter />}>
                                <Route path="/" element={<LoginLayout />}>
                                    <Route index element={<LoginForm />} />
                                    <Route path="forgot" element={<ForgotForm />} />
                                    <Route path="change-password/:token" element={<ChangePassForm />} />
                                </Route>
                                <Route path="holding" element={<HoldingPage />} />
                            </Route>
                        ) : (
                            <Route path="/" element={<PrivateRouter />}>
                                <Route
                                    index
                                    element={
                                        <Navigate
                                            to={
                                                [Role.Tenant].includes(authData.account.label) ? 'permits' : 'dashboard'
                                            }
                                        />
                                    }
                                />
                                {!authData.isBronzeRole() && (
                                    <>
                                        <Route element={<DashboardReportProvider />}>
                                            <Route path="dashboard" element={<Dashboard />} />
                                            <Route path="reports">
                                                <Route index element={<ReportDashboard />} />
                                                <Route path=":type/:index" element={<ReportPage />} />
                                            </Route>
                                        </Route>
                                        {isEnableManageCompany && (
                                            <Route path="companies" element={<CompanyProvider />}>
                                                <Route index element={<CompanyPage />} />
                                                <Route path=":idCompany" element={<RegionProvider />}>
                                                    <Route element={<LocationProvider />}>
                                                        <Route index element={<Location />} />
                                                        <Route
                                                            path="locations/:locationId/:locationTab"
                                                            element={<LocationDetail />}
                                                        />
                                                    </Route>

                                                    <Route path="employees" element={<EmployeeProvider />}>
                                                        <Route index element={<EmployeesPage />} />
                                                        <Route path="create" element={<FormEmployee />} />
                                                        <Route path="update/:idEmp" element={<FormEmployee />} />
                                                    </Route>
                                                </Route>
                                            </Route>
                                        )}
                                    </>
                                )}
                                <Route path="user" element={<AccountPage />} />
                                {account.isEnableIhubSupportFeature && (
                                    <Route path="support" element={<SupportLayout />} />
                                )}
                                <Route path="faqs" element={<FrequentlyAskedQuestions />} />
                                <Route
                                    path="tenants"
                                    element={
                                        ![Role.Tenant].includes(authData.account.label) ? (
                                            <TenantList />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />

                                {isEnableManagePermits && account.isEnablePermitFeature && (
                                    <Route path="permits" element={<FilterPermitProvider />}>
                                        <Route index element={<ListPermit />} />
                                        <Route path="add-permits" element={<UpsertPermit />} />
                                        <Route path="upload-permits" element={<UpLoadPermit />} />
                                        <Route path="edit-permit/:idPermit" element={<UpsertPermit />} />
                                    </Route>
                                )}
                                {isEnableManagePermits && [Role.AccountManager].includes(authData.account.label) && (
                                    <Route path="feedback" element={<FeedbackPage />} />
                                )}
                            </Route>
                        )}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                )}
            </AuthContext.Provider>
        </LanguageContext.Provider>
    );
}

export default App;
