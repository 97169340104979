import { RecurringType } from './PermitRecurring';

export interface ExcelRawPermit {
    zoneId: number;
    locationId: number;
    companyId: number;
    shopId: number;
    vrn: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    recurringType: RecurringType | undefined | null;
    type: string | undefined;
    startDate: undefined | string;
    endDate?: string | null;
    allDay?:undefined | string
    startTime: undefined | string;
    endTime: string | undefined;
    bayNumber: number | undefined;
    comment: string | undefined;
    status?: RawUploadPermitStatus;
    reason?: string | undefined;
    recurringEvery: number | null;
    requestedBy?: string | null;
}

export enum RawUploadPermitStatus {
    Valid = 0,
    InValid = 1,
    Existed = 2,
}
