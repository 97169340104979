/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { useEffect } from 'react';
import { Controller, FieldArrayWithId, UseFieldArrayReturn, UseFormReturn, useForm } from 'react-hook-form';
import * as yup from 'yup';
import BaseCheckbox from '../../../../../components/BaseCheckbox';
import IOSSwitch from '../../../../../components/IOSSwitch';
import LabelWithRequired from '../../../../../components/LabelWithRequired';
import color from '../../../../../config/Colors';
import { AllocatedPermitType, AllocatedType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { getLabelAllocatedTypeName } from '../helpers/TenantHelpers';
import { FormData } from './TenantComponents/ModelUpsertTenant';

type Props = {
    fieldData: FieldArrayWithId<FormData, 'allocatedTypes', 'id'>;
    useFields: UseFieldArrayReturn<FormData, 'allocatedTypes', 'id'>;
    formIndex: number;
    formData: UseFormReturn<FormData, any, undefined>;
    isEdit: boolean;
};

export enum DurationOptions {
    OneWeek = '1-week',
    TwoWeeks = '2-weeks',
    ThreeWeeks = '3-weeks',
    OneMonth = '1-month',
    TwoMonths = '2-months',
    ThreeMonths = '3-months',
    Bespoke = 'bespoke',
}

const durationOptionsList = [
    { value: DurationOptions.OneWeek, label: '1 Week' },
    { value: DurationOptions.TwoWeeks, label: '2 Weeks' },
    { value: DurationOptions.ThreeWeeks, label: '3 Weeks' },
    { value: DurationOptions.OneMonth, label: '1 Month' },
    { value: DurationOptions.TwoMonths, label: '2 Months' },
    { value: DurationOptions.ThreeMonths, label: '3 Months' },
    { value: DurationOptions.Bespoke, label: 'Bespoke' },
];

export default function AllocatedTypeItem({
    fieldData,
    useFields,
    formIndex,
    isEdit,
    formData: formDataParents,
}: Props) {
    const { usedPermit, allocatedPermitType } = fieldData;

    const schema = yup.object({
        isActive: yup.boolean().transform((value) => !!value),
        allocatedPermitType: yup.number(),
        usedPermit: yup.number(),
        amount: yup
            .number()
            .when(['isActive', 'isUnlimited'], {
                is: (isActive: boolean, isUnlimited: boolean) => isActive && !isUnlimited,
                then: (schema) =>
                    schema
                        .required('Allocated amount is required')
                        .test('min-amount', 'Max permits cannot be less than the required minimum', function (value) {
                            // const { isEdit, usedPermit, allocatedPermitType } = this.options.context as any;
                            let minValue = 1;

                            if (isEdit) {
                                const used = usedPermit || 0;
                                minValue = Math.max(used, 1);
                            }

                            if (value === undefined || value === null) {
                                return this.createError({
                                    message: `Max permits cannot be less than ${minValue}`,
                                });
                            }

                            if (value < minValue) {
                                return this.createError({
                                    message: `Max permits cannot be less than ${minValue}`,
                                });
                            }

                            return true;
                        }),
                otherwise: (schema) => schema.notRequired(),
            })
            .typeError('This field is required')
            .required('This field is required'),
        isUnlimited: yup.boolean(),
        durationOptions: yup.string(),
        monthValue: yup
            .number()
            .notRequired()
            .typeError('This field is required')
            .when('durationOptions', {
                is: DurationOptions.Bespoke,
                then: (schema) =>
                    schema.test('one-of-two', 'Either month or day must have a value', function (value) {
                        const { dayValue } = this.parent;
                        return value || dayValue;
                    }),
            }),
        dayValue: yup
            .number()
            .notRequired()
            .typeError('This field is required')
            .when('durationOptions', {
                is: DurationOptions.Bespoke,
                then: (schema) =>
                    schema.test('one-of-two', 'Either month or day must have a value', function (value) {
                        const { monthValue } = this.parent;
                        return value || monthValue;
                    }),
            }),
    });

    const formData = useForm<AllocatedType>({
        mode: 'all',
        defaultValues: {
            ...fieldData,
            durationOptions: fieldData.durationOptions ?? DurationOptions.OneMonth,
            isUnlimited: fieldData.isUnlimited ?? false,
        },
        resolver: yupResolver(schema),
        context: { isEdit, usedPermit, allocatedPermitType },
    });

    const {
        control,
        formState: { isValid },
        setError,
        watch,
    } = formData;

    // Set Valid to parents
    useEffect(() => {
        formDataParents.setValue(`allocatedTypes.${formIndex}.isValid`, isValid);
    }, [isValid]);

    useEffect(() => {
        if (watch().isActive && Number(watch().amount) === 0 && !watch().isUnlimited) {
            setError(`amount`, {
                type: 'min',
                message: `Max permits can not be less than ${isEdit ? usedPermit : 1}`,
            });
        }
    }, [watch().isActive, watch().isUnlimited]);

    useEffect(() => {
        if (!watch().isActive && allocatedPermitType === AllocatedPermitType.Temporary) {
            formData.setValue('durationOptions', DurationOptions.OneMonth);
        }
    }, [watch().isActive, allocatedPermitType]);

    useEffect(() => {
        if (isNumber(watch().monthValue) && isNumber(watch().dayValue)) {
            if (watch().monthValue === 0 && watch().dayValue === 0) {
                setError(`monthValue`, {
                    type: 'min',
                    message: `Either month or day must have a value`,
                });
                setError(`dayValue`, {
                    type: 'min',
                    message: `Either month or day must have a value`,
                });
            } else {
                formData.clearErrors('monthValue');
                formData.clearErrors('dayValue');
            }
        }
    }, [watch().monthValue, watch().dayValue]);

    return (
        <Stack sx={{ borderRadius: '5px', background: color.grey100, border: '1px solid #eeeeee' }}>
            <Grid
                container
                key={fieldData.id}
                sx={{ background: 'white', p: `18px 12px 12px 12px`, borderRadius: '5px' }}
            >
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name={`isActive`}
                        render={({ field, fieldState }) => (
                            <BaseCheckbox
                                {...field}
                                label={
                                    <Stack>
                                        <Typography sx={{ ml: 1 }}>
                                            {getLabelAllocatedTypeName(fieldData.allocatedPermitType)}
                                            {': '}
                                        </Typography>
                                        {isEdit && (
                                            <Typography variant="body2" sx={{ ml: 1, color: color.success }}>
                                                {`Used: ${usedPermit} / ${
                                                    fieldData.isUnlimited ? 'Unlimited' : fieldData.amount
                                                }`}
                                            </Typography>
                                        )}
                                    </Stack>
                                }
                                checkBoxProps={{
                                    checked: field.value,
                                    onChange(event, checked) {
                                        // Check one day permit valid
                                        if (
                                            !checked &&
                                            isEdit &&
                                            usedPermit! > 0 &&
                                            allocatedPermitType !== AllocatedPermitType.Once
                                        )
                                            return;

                                        if (!checked) {
                                            const activeLength =
                                                formDataParents
                                                    .getValues()
                                                    .allocatedTypes?.map((f) => f.isActive)
                                                    .filter((i) => i === true).length ?? 0;
                                            if (activeLength > 1) {
                                                formDataParents.setValue(
                                                    `allocatedTypes.${formIndex}.isActive`,
                                                    checked
                                                );
                                                field.onChange(checked);
                                                formData.clearErrors();
                                            }
                                        } else {
                                            formDataParents.setValue(`allocatedTypes.${formIndex}.isActive`, checked);
                                            field.onChange(checked);
                                        }
                                    },
                                    disabled: false,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Controller
                        control={control}
                        name={`amount`}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                error={!!error?.message}
                                helperText={error?.message}
                                fullWidth
                                label={<LabelWithRequired label="Max Number of Permits" />}
                                placeholder=""
                                onInput={(e: any) => {
                                    e.target.value =
                                        Math.max(0, parseInt(e.target.value) || 0)
                                            .toString()
                                            .slice(0, 11) || 0;
                                }}
                                onChange={(e) => {
                                    field.onChange(Number(e.target.value));
                                    formDataParents.setValue(
                                        `allocatedTypes.${formIndex}.amount`,
                                        Number(e.target.value)
                                    );
                                }}
                                inputProps={{
                                    maxLength: 11,
                                }}
                                value={field.value}
                                disabled={!formData.getValues().isActive || formData.getValues().isUnlimited}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={'auto'} sx={{ pl: 2 }}>
                    <Controller
                        name="isUnlimited"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <IOSSwitch
                                {...field}
                                label={<Typography sx={{ minWidth: '55px' }}>Unlimited</Typography>}
                                iosSwitchProps={{
                                    checked: field.value,
                                    // disabled: [AllocatedPermitType.Once].includes(fieldData.allocatedPermitType),
                                    onChange(event, checked) {
                                        field.onChange(event);
                                        formDataParents.setValue(
                                            `allocatedTypes.${formIndex}.isUnlimited`,
                                            event.target.checked
                                        );
                                        if (event.target.checked) {
                                            formData.clearErrors();
                                        }
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                {allocatedPermitType === AllocatedPermitType.Temporary && (
                    <Grid item xs={12} mt={2}>
                        <FormControl fullWidth>
                            <InputLabel>Set Duration</InputLabel>
                            <Controller
                                control={control}
                                name="durationOptions"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            formDataParents.setValue(
                                                `allocatedTypes.${formIndex}.durationOptions`,
                                                e.target.value
                                            );
                                        }}
                                        label="Set Duration"
                                        disabled={!watch().isActive}
                                    >
                                        {durationOptionsList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
                {formData.getValues('durationOptions') === DurationOptions.Bespoke && (
                    <Stack width={'100%'} flexDirection={'row'} gap={1} sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="monthValue"
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!error?.message}
                                        helperText={error?.message}
                                        label="Month(s)"
                                        placeholder="Enter number of months"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        onInput={(e: any) => {
                                            e.target.value =
                                                Math.max(0, parseInt(e.target.value) || 0)
                                                    .toString()
                                                    .slice(0, 11) || 0;
                                        }}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            formDataParents.setValue(
                                                `allocatedTypes.${formIndex}.monthValue`,
                                                e.target.value as unknown as number
                                            );
                                            if (Number(e.target.value) > 0) {
                                                formData.clearErrors('dayValue');
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="dayValue"
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!error?.message}
                                        helperText={error?.message}
                                        onInput={(e: any) => {
                                            e.target.value =
                                                Math.max(0, parseInt(e.target.value) || 0)
                                                    .toString()
                                                    .slice(0, 11) || 0;
                                        }}
                                        label="Day(s)"
                                        placeholder="Enter number of days"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            formDataParents.setValue(
                                                `allocatedTypes.${formIndex}.dayValue`,
                                                e.target.value as unknown as number
                                            );
                                            if (Number(e.target.value) > 0) {
                                                formData.clearErrors('monthValue');
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Stack>
                )}
            </Grid>
        </Stack>
    );
}
