import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    Stack,
    Typography,
    styled,
} from '@mui/material';
import { useContext, useState } from 'react';
import RemoveIcon from '../../../../../../assets/images/RemoveIcon';
import ICAddTenant from '../../../../../../assets/svg/ICAddTenant';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { AllocatedPermitType, AllocatedType } from '../../../../../../models/tenant/AllocatedTypeWithAmount';
import { Shop } from '../../../../../../models/tenant/Shop';
import ExplantedIcon from '../../../../../permitsLayout/addEditPermit/components/svg/ExplantedIcon';
import IconEdit from '../../../../employees/components/IconEdit';
import { getLabelAllocatedTypeName } from '../../helpers/TenantHelpers';
import ShopUserTableItem from './ShopUserTableItem';
import { TenantContext } from '../../../../../../hooks/useTenants';

type Props = {
    shop: Shop;
    handleEditModel: (shop: Shop) => void;
    handleDelete: (idShop: number) => void;
    isShowAction?: boolean;
};

export default function ShopItem({ shop, handleEditModel, handleDelete, isShowAction }: Props) {
    const [isEnplaned, setEnplaned] = useState<boolean>(false);
    const { t } = useContext(LanguageContext);
    const { modalUpsertUser, shopSelect, setShopSelect, userFormData } = useContext(TenantContext);

    const activeAllocatedTypes = shop.allocatedTypes?.filter((allocatedType) => allocatedType.isActive);
    const withOutOneDay = shop.allocatedTypes?.filter(
        (allocatedType) => allocatedType.allocatedPermitType !== AllocatedPermitType.Once
    );
    const usedPermit = withOutOneDay?.reduce((acc, cur) => acc + cur.usedPermit, 0);
    const allocated = withOutOneDay?.reduce((acc, cur) => acc + cur.amount, 0);

    const renderAllocatedTypes = (allocatedTypes: AllocatedType[]) => {
        const components = allocatedTypes.map((allocatedType: AllocatedType, index) => {
            const isOneDay = allocatedType.allocatedPermitType === AllocatedPermitType.Once;
            const isLast = index === allocatedTypes.length - 1;
            return (
                <>
                    <Typography variant="body1" component={'span'} color={color.grey600}>
                        {`${getLabelAllocatedTypeName(allocatedType.allocatedPermitType)}: ${
                            `${allocatedType.usedPermit} (${ allocatedType.isUnlimited? 'Unlimited' : allocatedType.amount})`
                        }`}
                    </Typography>
                    {!isLast && (
                        <Typography variant="body1" component={'span'} color={color.grey600}>{` | `}</Typography>
                    )}
                </>
            );
        });

        return components;
    };

    const handleAddUserToShop = () => {
        userFormData.reset({ id: 0, email: '', firstName: '', phoneNumber: '', lastName: '', accountId: 0 });
        setShopSelect(shop);
        modalUpsertUser.handleOpen();
    };

    return (
        <Grid item xs={12}>
            <MyAccordion disableGutters elevation={0} expanded={isEnplaned}>
                <MyAccordionSummary
                    expandIcon={
                        <IconButton
                            onClick={() => setEnplaned(!isEnplaned)}
                            sx={{ p: '2px', width: '20px', height: '20px' }}
                        >
                            <ExplantedIcon />
                        </IconButton>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '&.MuiAccordionSummary-root': {
                            borderBottom: isEnplaned ? `1px solid ${color.grey300}` : `none`,
                        },
                        '& div': { background: `${color.grey100} !important` },
                    }}
                >
                    <Grid container display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Grid item xs>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" fontWeight={500} component="span" color={color.priText}>
                                        {shop.tenantName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color={color.grey600}>
                                        User(s): {shop.shopClients?.length} | Used permits: {usedPermit ?? 0}(
                                        {allocated ?? 0})
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {renderAllocatedTypes(activeAllocatedTypes ?? [])}
                                </Grid>
                            </Grid>
                        </Grid>
                        {isShowAction && (
                            <Grid item xs="auto">
                                <Stack direction="row" gap={1}>
                                    <IconButtonWithTooltip
                                        function={handleAddUserToShop}
                                        tooltipTitle={'Add user'}
                                        icon={<ICAddTenant />}
                                    />

                                    <IconButtonWithTooltip
                                        function={() => handleEditModel(shop)}
                                        tooltipTitle={'Edit'}
                                        icon={<IconEdit />}
                                    />

                                    <IconButtonWithTooltip
                                        function={() => handleDelete(shop.id)}
                                        tooltipTitle={'Delete'}
                                        icon={<RemoveIcon style={{ stroke: color.danger }} />}
                                        type="danger"
                                    />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </MyAccordionSummary>
                <AccordionDetails sx={{ padding: 2 }}>
                    {shop.shopClients?.length ? (
                        <Stack>
                            <ShopUserTableItem shop={shop} isShowAction={isShowAction}/>
                        </Stack>
                    ) : (
                        <Stack sx={{ background: color.grey100 }} p={2.5}>
                            <Typography color={color.grey600} textAlign={'center'} fontWeight={500}>
                                There are currently no users. Please add a new user
                            </Typography>
                        </Stack>
                    )}
                </AccordionDetails>
            </MyAccordion>
        </Grid>
    );
}

export const MyAccordion = styled(Accordion)({
    '&.MuiPaper-root': {
        border: `1px solid #DDDDDD`,
        boxShadow: `none`,
    },
});

export const MyAccordionSummary = styled(AccordionSummary)({
    '&.MuiAccordionSummary-root': {
        flexDirection: 'row-reverse',
        gap: '8px',
        padding: '12px 16px',
        background: color.grey100,
        borderRadius: `4px 4px 0px 0px`,
        borderBottom: `1px solid ${color.grey300}`,
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },

    '&.MuiAccordionSummary-root:hover': {
        cursor: 'default',
    },
});
