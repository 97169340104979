import React, { useContext, useEffect, useState } from 'react';
import ChartIcon from '../../assets/images/ChartIcon';
import Companies from '../../assets/images/Companies';
import MailIcon from '../../assets/images/MailIcon';
import PermitsIcon from '../../assets/images/PermitsIcon';
import Reports from '../../assets/images/Report';
import UserIcon from '../../assets/images/UserIcon';
import { appConfig } from '../../config/AppConfig';
import { LanguageContext } from '../../contexts/useLanguage';
import { AuthContext } from '../../hooks/useAuth';
import useMedia from '../../hooks/useMedia';
import { DrawerItem } from '../../models/DrawerItem';
import FAQs from '../../assets/images/FaqIcon';
import { isGoldRole } from '../permitsLayout/addEditPermit/components/UpsertPermitItem/helpers';
import FeedBackIcon from '../../assets/images/FeedBackIcon';
import TenantListIcon from '../../assets/images/TenantListIcon';

export default function useDrawer() {
    const { t } = useContext(LanguageContext);
    const { logout, account } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState<number>(1);
    const [openDrawerSmall, setOpenDrawerSmall] = useState(false);
    const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);
    const { isMobileSM, isMediumMD } = useMedia();

    useEffect(() => {
        isMobileSM ? setIsShowDrawer(false) : setIsShowDrawer(true);
    }, [isMobileSM]);

    const handleDrawerClose = () => {
        if (isMediumMD) {
            setOpenDrawerSmall(true);
            setIsShowDrawer(true);
        } else {
            setOpen(!open);
        }
    };

    const handleDrawerOpen = () => {
        if (isMediumMD) {
            setOpenDrawerSmall(true);
            setIsShowDrawer(false);
        } else {
            setOpen(!open);
        }
    };

    const changeIsShowDrawer = () => {
        setIsShowDrawer(!isShowDrawer);
    };

    const fullMenu = [
        {
            id: 1,
            label: t('drawer:dashboard'),
            icon: <ChartIcon className="icon-svg-fill" />,
            path: '',
        },
        {
            id: 2,
            label: t('drawer:companies'),
            icon: <Companies className="svg-item-Menu" />,
            path: 'companies',
        },
        {
            id: 3,
            label: t('drawer:insights'),
            icon: <Reports className="svg-item-Menu" />,
            path: `reports`,
        },
        {
            id: 4,
            label: t('drawer:user'),
            icon: <UserIcon className="svg-item-Menu" />,
            path: 'user',
        },
        {
            id: 5,
            label: t('drawer:permits'),
            icon: <PermitsIcon className="svg-item-Menu" />,
            path: 'permits',
        },
        {
            id: 7,
            label: 'FAQs',
            icon: <FAQs className="svg-item-Menu" />,
            path: 'faqs',
        },
        {
            id: 9,
            label: t('drawer:tenantList'),
            icon: <TenantListIcon className="svg-item-Menu" />,
            path: 'tenants',
        },
    ];

    if (account.isEnableIhubSupportFeature) {
        fullMenu.push({
            id: 6,
            label: t('drawer:support'),
            icon: <MailIcon className="svg-item-Menu" />,
            path: 'support',
        });
    }

    if (isGoldRole(account.label)) {
        fullMenu.push({
            id: 8,
            label: t('drawer:feedback'),
            icon: <FeedBackIcon className="svg-item-Menu" />,
            path: 'feedback',
        });
    }

    let ListDrawItem: DrawerItem[] = fullMenu;

    if (true) {
        const idsToRemove: number[] = [];
    
        if (appConfig.flagFeatures.isEnableManageCompany === false) {
            idsToRemove.push(2);
        }
        if (appConfig.flagFeatures.isEnableManagePermits === false) {
            idsToRemove.push(5);
        }
        if (['Tenant'].includes(account?.label!)) {
            idsToRemove.push(1, 2, 3, 9);
        }
        if (!account.isEnablePermitFeature) {
            idsToRemove.push(5);
        }
    
        ListDrawItem = ListDrawItem.filter((item) => !idsToRemove.includes(item.id));
    }

    return {
        ListDrawItem,
        open,
        setOpen,
        handleDrawerClose,
        logout,
        activeItem,
        setActiveItem,
        openDrawerSmall,
        setOpenDrawerSmall,
        isShowDrawer,
        changeIsShowDrawer,
        isMobileSM,
        isMediumMD,
        setIsShowDrawer,
        handleDrawerOpen,
    };
}

export const DrawerContext = React.createContext<ReturnType<typeof useDrawer>>({} as any);
