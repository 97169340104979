import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { generateRandom } from '../../helpers';
import { Pagination } from '../../models/BaseFilter';
import { ExcelRawPermit } from '../../models/permits/ExcelRawPermit';
import { FilterPermits } from '../../models/permits/FilterPermits';
import { Permit, PermitListItem } from '../../models/permits/Permit';
import { PermitEdit, PermitWithInfo } from '../../models/permits/PermitEdit';
import { VRNChecking } from '../../models/permits/VRNChecking';
import { CheckingVRNItem } from '../../pages/permitsLayout/addEditPermit/hooks/useUpsertPermit';
import { RecurringType } from './../../models/permits/PermitRecurring';
import { VRN } from '../../models/permits/VRN';
export class PermitController extends BaseHttpController<string> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Permit', client);
    }

    async getList(filter: FilterPermits): Promise<Pagination<PermitListItem>> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }

    async getPermitTypes() {
        const res = await this.doGet({ path: 'GetPermitType', config: {} });
        return res;
    }

    async deletePermit(number: number[]) {
        const res = await this.doPost({ path: 'Delete', body: { permitId: number, shopId: 0 }, config: {} });
        return res;
    }

    async Upsert(permits: Permit[]): Promise<Permit[]> {
        const res = await this.doPost({ path: 'Upsert', body: permits, config: {} });
        return res.data;
    }

    async getPermitInfo(id: number): Promise<PermitEdit> {
        const res = await this.doGet({ path: `${id}`, config: {} });
        return res.data;
    }

    async checkVRN(
        listVrn: ExcelRawPermit[],
        setProgress?: React.Dispatch<React.SetStateAction<number>>
    ): Promise<ExcelRawPermit[]> {
        const res = await this.doPost({ path: 'checkPermit', body: listVrn, config: {} });
        return res.data;
    }

    async checkVRNUpsert(listVrn: CheckingVRNItem[]): Promise<CheckingVRNItem[]> {
        const res = await this.doPost({ path: 'checkPermit', body: listVrn, config: {} });
        return res.data;

        // const arr: CheckingVRNItem[] = []
        // listVrn.map(item => {
        //     const status = generateRandom(1, 3)
        //     arr.push({ ...item, status: status === 1 ? RawUploadPermitStatus.InValid : RawUploadPermitStatus.Valid, reason: status === 1 ? "Error 123123123123123123123123123123" : "" }
        //     )
        // })
        // return arr
    }

    async DownloadExampleSheet(): Promise<File> {
        const res = await this.doGet({ path: `DownloadPermitTemplate`, config: { responseType: 'blob' } });
        return res.data;
    }

    async DownloadPermitList(props: { filter: FilterPermits }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadPermitsList`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }

    async lookup(vrn: string): Promise<VRN> {
        const res = await this.doGet({ path: `Lookup?vrn=${vrn}`, config: {} });
        return res.data;
    }

    async getPermitScopeInfoById(id: number): Promise<PermitWithInfo> {
        const res = await this.doGet({ path: `getPermitScope/${id}`, config: {} });
        return res.data;
    }
}

export const returnDataVRNChecking: VRNChecking[] = Array.from(new Array(25)).map((item, number) => {
    return {
        zoneId: 48,
        vrn: 'VRN ' + generateRandom(100, 9999),
        recurringType: RecurringType.once,
        startDate: new Date(),
        startTime: 1,
        endTime: 2,
        status: generateRandom(1, 3) === 1 ? 'Invalid' : 'Valid',
    };
});
