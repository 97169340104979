import { Account } from '../Account';
import { BaseModel } from '../BaseModel';
import Zone from '../Zone';
import { Role } from '../permission/Role';
import { AllocatedType } from './AllocatedTypeWithAmount';

export interface Shop extends BaseModel {
    id: number;
    tenantName: string;
    managerName: string;

    phoneNumber?: string;
    locationId?: number;
    locationName?: string;
    clientId?: number;
    bayInfo?: string;
    isEditBayInfo?: boolean;
    isSendMail?: boolean;
    usedPermit?: number;
    lastEmailSentTime?: Date;
    emailCount?: number;
    firstTimeLoginIhub?: Date;
    maxAllocatedAmount?: number;
    maxPermits?: number;
    isLockScreenPIN: boolean;
    isRevealPermit?: boolean;
    isEnableIhubSupportFeature?: boolean;

    pin: string | null;

    shopClients?: ShopClient[];
    allocatedTypes?: AllocatedType[];
    shopZones?: ShopZone[];

    clientUpsert?: Client;
}

export interface ShopZone extends BaseModel {
    allocatedAmount: number;
    shopId: number;
    zone?: Zone;
    zoneId: number;
    isUnlimited: boolean;
}

export interface ShopClient {
    clientId: number;
    id: number;
    shopId: number;
    accountId?: number;
    client?: Client;
}

export interface Client extends BaseModel {
    accountId?: number;
    email: string;
    firstName: string;
    label?: Role;
    lastName: string;
    phoneNumber: string;
    isEnableITicketLite?: boolean;

    userAccount?: Account;
    appLogins?: ClientAppLogin[];
}

export interface ClientAppLogin {
    clientId: number;
    application: ClientLoginApplication;
}

export enum ClientLoginApplication {
    iHub,
    iPark,
    iTicketLite,
}
