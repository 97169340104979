import moment from 'moment';
import { LoadingFilter } from '../../../../../../hooks/useFilter';
import { FilterLocation, FilterTenant } from '../../../../../../models/BaseFilter';
import { Filter } from '../../../../../../models/Filter';
import { Role } from '../../../../../../models/permission/Role';
import { Shop } from '../../../../../../models/tenant/Shop';
import { UpsertFilter } from '../../../hooks/useUpsertPermit';
import { addMonths, getDate, getMonth, isValid } from 'date-fns';
import { Permit } from '../../../../../../models/permits/Permit';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';

export const ALL_DAY_SECONDS = 24 * 60 * 60;
export const DYNAMIC_TYPES = [RecurringType.day, RecurringType.week, RecurringType.month];

export function isNumeric(value: any) {
    return /^-?\d+$/.test(value);
}

export const isGoldRole = (role: Role) => {
    return [Role.AccountManager].includes(role);
};

export const isSilverRole = (role: Role) => {
    return [Role.Client, Role.Employee, Role.ManagingAgent].includes(role);
};

export const isBronzeRole = (role: Role) => {
    return [Role.Tenant].includes(role);
};

export const defaultFilter = (filter: UpsertFilter) => {
    return {
        companyId: filter.companyId ?? 0,
        locationId: filter.locationId,
        regionId: filter.regionId,
        zoneId: filter.zoneId ?? 0,
        from: new Date(),
        to: new Date(),
    };
};

export const defaultTenantFilter: FilterTenant = {
    page: 1,
    pageSize: 1000,
    search: {
        fields: [],
        value: '',
    },
    filter: {
        locationId: 0,
    },
};

export const defaultLocationFilter: FilterLocation = {
    pageSize: 10000,
    page: 1,
    filter: { companyId: 0, regionId: 0 },
    search: {
        fields: [],
        value: '',
    },
};

export const defaultLoading: LoadingFilter = {
    isLoadingCompany: false,
    isLoadingLocation: false,
    isLoadingRegion: false,
    isLoadingZone: false,
    isLoadingShop: false,
};

export const getDateWeekRecur = (date: Date, endDateOfWeek: Date, recurEvery: number) => {
    const momentDate = moment(date);
    const momentEndDateOfWeek = moment(endDateOfWeek);

    if (momentDate.isAfter(momentEndDateOfWeek)) {
        if (recurEvery === 1) {
            return date;
        }

        return momentDate.add((recurEvery - 1) * 7, 'day').toDate();
    }

    return date;
};

export const genWeekDates = (date: Date) => {
    const rs = Array.from(new Array(7)).map((a, index) => {
        return moment(date).add(index, 'day').toDate();
    });

    return rs;
};

export const calculateFirstDate = (validateFrom: Date, recurringEvery: number) => {
    let defaultDay = new Date();
    for (let index = getMonth(validateFrom!); index < 13; index++) {
        const date = moment({
            year: validateFrom!.getFullYear(),
            month: index,
            day: recurringEvery,
        }).toDate();
        if (isValid(date)) {
            defaultDay = date;
            break;
        } else continue;
    }
    if (recurringEvery < getDate(validateFrom!)) {
        return addMonths(defaultDay, 1);
    }

    return defaultDay;
};

export const getTime = (date: Date, permit: Permit, oldRecur: RecurringType) => {
    const currentRecur = permit.permitRecurring![0].recurringType;
    if (oldRecur === currentRecur) {
        return new Date(date);
    } else {
        return null;
    }
};
