import { IconButton, SxProps, Theme, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import React from 'react';
import color from '../config/Colors';

type Props = {
    tooltipTitle: string | React.ReactNode;
    function: Function;
    icon: React.ReactNode;
    sxIcon?: SxProps<Theme>;
    type?: TooltipType;
};

export type TooltipType = 'danger' | 'warning' | 'info' | 'success';

export default function IconButtonWithTooltip(props: Props) {
    const isDanger = props.type === 'danger';
    const content = () => {
        return (
            <span>
                <IconButton
                    onClick={() => props.function()}
                    aria-label="fingerprint"
                    color="success"
                    sx={{
                        backgroundColor: color.grey200,
                        '& .svg-icon-stroke':
                            props.type === 'danger'
                                ? {
                                      stroke: color.danger,
                                  }
                                : { stroke: color.grey600 },
                        '&:hover': {
                            backgroundColor: isDanger ? color.lightDanger : color.lightPrimary,
                        },
                        border: `5px solid ${color.grey100}`,
                        width: '32px',
                        height: '32px',
                        '& .Mui-disabled': {
                            background: color.grey400,
                        },
                        '&.MuiIconButton-root': { p: 0.5 },
                        ...props.sxIcon,
                    }}
                >
                    {props.icon}
                </IconButton>
            </span>
        );
    };

    return props.type === 'danger' ? (
        <DangerTooltip
            title={typeof props.tooltipTitle === 'string' ? TooltipTypo(props.tooltipTitle) : props.tooltipTitle!}
            placement="top"
        >
            {content()}
        </DangerTooltip>
    ) : (
        <Tooltip title={TooltipTypo(props.tooltipTitle)} placement="top">
            {content()}
        </Tooltip>
    );
}

const TooltipTypo = (children: React.ReactNode) => {
    return (
        <Typography fontSize={{ xs: '10px', sm: '12px' }} fontWeight={500} color={color.white}>
            {children}
        </Typography>
    );
};

export const DangerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.danger,
        color: color.white,
    },
}));
