export interface AllocatedType {
    clientId: number;
    allocatedPermitType: AllocatedPermitType;
    amount: number;
    isActive: boolean;
    locationId: number;
    isUnlimited: boolean;
    durationType: DurationType;
    duration: number;

    usedPermit: number;

    isValid?: boolean;
    durationOptions?: string;
    monthValue?: number;
    dayValue?: number;
}

export enum AllocatedPermitType {
    Once = 0,
    Temporary = 6,
    Indefinite = 5,
    Dynamic = 7,
}

export enum DurationType {
    Week,
    Month,
    Bespoke,
}
