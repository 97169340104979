/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { shopController, userController } from '../controllers';
import { FilterTenant, Pagination, TenantsPagination } from '../models/BaseFilter';
import { Client, Shop } from '../models/tenant/Shop';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LanguageContext } from '../contexts/useLanguage';
import { useForm } from 'react-hook-form';
import useModal from '../components/modal/useModal';
import useYupSchema from '../helpers/useYupSchema';

export interface StateTenant {
    tenantPagination: TenantsPagination;
    filter: FilterTenant;
    isLoadingDataTenant: boolean;
    isRefreshDataTenant: boolean;
    listLocation: Location[];
}

interface TenantFilterState {
    locationId: number;
    searchValue: string;
    page: number;
}

export enum ParamsFilterTenant {
    search = 'search',
    page = 'page',
}

export default function useTenants() {
    const { locationId } = useParams();
    const { t } = useContext(LanguageContext);
    const modalUpsertUser = useModal();
    const modalConfirmDeleteUser = useModal();
    const [loadingDeleteUser, setLoadingDeleteUser] = useState(false);

    const [shopSelect, setShopSelect] = useState<Shop>({} as Shop);
    const [idUserDelete, setIdUserDelete] = useState<number>();

    const [tenantPaging, setTenantPaging] = useState<Pagination<Shop>>({
        data: [],
        page: 1,
        pageSize: 6,
        total: 0,
        totalPage: 0,
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        search: searchParams.get(ParamsFilterTenant.search),
        page: searchParams.get(ParamsFilterTenant.page),
    };

    const [isLoadingTenant, setIsLoadingTenant] = useState(true);

    const _initFilter: TenantFilterState = {
        locationId: Number(locationId),
        searchValue: '',
        page: 1,
    };

    const [tenantFilterState, setTenantFilterState] = useState<TenantFilterState>({
        locationId: Number(locationId),
        searchValue: !!params.search ? params.search : '',
        page: !!params.page ? Number(params.page) : 1,
    });

    useEffect(() => {
        if (Number(locationId) !== 0) {
            getTenant();
        }
    }, [tenantFilterState]);

    useEffect(() => {
        handleChangeFilterToParams();
    }, [tenantFilterState, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});
        if (tenantFilterState.searchValue && tenantFilterState.searchValue !== '')
            newParams.append(ParamsFilterTenant.search, tenantFilterState.searchValue ?? '');
        if (tenantFilterState.page && tenantFilterState.page !== 1)
            newParams.append(ParamsFilterTenant.page, tenantFilterState.page.toString());

        setSearchParams(newParams);
    };

    const getTenant = () => {
        setIsLoadingTenant(true);
        shopController
            .filter({
                page: tenantFilterState.page,
                pageSize: 6,
                search: {
                    fields: [''],
                    value: tenantFilterState.searchValue,
                },
                filter: {
                    locationId: tenantFilterState.locationId,
                },
            })
            .then((res) => {
                setTenantPaging(res);
            })
            .finally(() => setIsLoadingTenant(false));
    };

    const handleResetDataTenant = (props?: Partial<TenantFilterState>) =>
        setTenantFilterState({ ..._initFilter, ...props });

    const { yupValidateUser } = useYupSchema();

    const userFormData = useForm<Client>({
        mode: 'all',
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            id: 0,
            accountId: 0,
        },
        resolver: yupResolver(yupValidateUser),
    });

    const handleDeleteUser = (id: number) => {
        setIdUserDelete(id);
        modalConfirmDeleteUser.handleOpen();
    };

    const handleConfirmDeleteUser = () => {
        if (idUserDelete) {
            setLoadingDeleteUser(true);
            userController
                .delete(idUserDelete.toString())
                .then(() => {
                    //close popup
                    modalConfirmDeleteUser.handleClose();
                    //refresh tenant
                    getTenant();
                })
                .finally(() => setLoadingDeleteUser(false));
        }
    };

    return {
        getTenant,
        tenantPaging,
        setTenantFilterState,
        tenantFilterState,
        isLoadingTenant,
        handleResetDataTenant,
        setTenantPaging,
        userFormData,
        modalUpsertUser,
        shopSelect,
        setShopSelect,
        modalConfirmDeleteUser,
        loadingDeleteUser,
        setLoadingDeleteUser,
        handleDeleteUser,
        handleConfirmDeleteUser,
    };
}

export const TenantContext = React.createContext<ReturnType<typeof useTenants>>({} as any);
