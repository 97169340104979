/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IAutoCompleteRF from '../../../../components/autoComplete/IAutoComplete2';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { companiesController, locationController, shopController, zoneController } from '../../../../controllers';
import { AuthContext } from '../../../../hooks/useAuth';
import { LoadingFilter } from '../../../../hooks/useFilter';
import { Company } from '../../../../models/Company';
import { LocationWithDetail } from '../../../../models/Location';
import Zone from '../../../../models/Zone';
import { Role } from '../../../../models/permission/Role';
import { Shop } from '../../../../models/tenant/Shop';
import { UpsertPermitContext } from '../hooks/useUpsertPermit';
import {
    defaultLoading,
    defaultLocationFilter,
    defaultTenantFilter,
    isGoldRole,
    isSilverRole,
} from './UpsertPermitItem/helpers';
import IAutoCompleteLocation from '../../../../components/autoComplete/IAutoCompleteLocation';

type Props = {
    setIsNoneShops: React.Dispatch<React.SetStateAction<boolean>>;
    handleClearOldParams: () => void;
    isFromStel: boolean;
};

export type StelParams = {
    idCompany: number;
    idLocation: number;
    idZone: number;
};

export type SelectField = 'tenant' | 'zone';
export type AccountManagerSelectedField = 'location' | 'company';

export default function UpsertPermitFilter({
    setIsNoneShops,
    handleClearOldParams,
    isFromStel,
}: // setStelParams,
// stelParams,
Props) {
    const {
        isEdit,
        stateDialog,
        isContinueUsingPermit,
        setIsContinueUsingPermit,
        setIsScopeValid,
        setTenantLocationId,
        setPartialInitData,
        dataState,
        initData,
        setPartialData,
        filterState,
        setPartialFilter,
    } = useContext(UpsertPermitContext);
    const { account, isBronzeRole, oldParams } = useContext(AuthContext);
    const { t } = useContext(LanguageContext);
    const [loading, setLoading] = useState<LoadingFilter>(defaultLoading);
    const setPartialLoading = (partial: Partial<LoadingFilter>) => setLoading((prev) => ({ ...prev, ...partial }));
    const [selectedField, setSelectedField] = useState<SelectField>();
    const [ACSelectedField, setACSelectedField] = useState<AccountManagerSelectedField>();
    const isTenant = [Role.Tenant].includes(account.label);

    const { idPermit } = useParams();

    useEffect(() => {
        // if (isNavigateFromStella || isFromStel) return;
        //drill down from companies
        if (isGoldRole(account.label)) {
            getCompanies({ isSelectCompanyFirst: !!filterState.companyId });
            getInfoByClient({
                companyId: 0,
                isSetInitData: true,
                isSelectCompanyFirst: !!filterState.companyId,
            });

            if (filterState.locationId) {
                getMyZones({
                    locationId: filterState.locationId,
                    shopId: filterState.shopId ?? 0,
                    isSetInit: true,
                    isSetZone: oldParams.zoneId !== null,
                });

                oldParams.zoneId === null &&
                    getShopByLocation({ locationId: filterState.locationId, isSetInit: true, isSetShop: true });
            }
        } else if (isSilverRole(account.label)) {
            getInfoByClient({ companyId: 0 });
            if (!!filterState.locationId) {
                getShopByLocation({ locationId: filterState.locationId, isSetInit: true });
                getMyZones({ locationId: filterState.locationId, shopId: 0, isSetInit: true });
            }
        }
        //drill down from location
        //drill down from zone
        else if (isBronzeRole()) getAllocatedZonesByShop();
    }, []);

    //check isFilter valid
    useEffect(() => {
        if (isEdit) return setIsScopeValid(true);
        if (isBronzeRole()) return setIsScopeValid(true);

        if (isSilverRole(account.label)) {
            if (!filterState.locationId) return setIsScopeValid(false);
            else setIsScopeValid(true);
        }

        if (isGoldRole(account.label)) {
            if (!filterState.companyId) return setIsScopeValid(false);
            else {
                if (ACSelectedField === 'company') {
                    return setIsScopeValid(!!filterState.companyId);
                } else {
                    return setIsScopeValid(!!filterState.locationId);
                }
            }
        }
    }, [filterState]);

    //catch event close confirm popup
    useEffect(() => {
        if (isContinueUsingPermit) {
            const filterClone = { ...filterState };
            if (isFromStel && !isGoldRole(account.label)) {
                setIsNoneShops(false);
                handleClearOldParams();
                filterClone.companyId = undefined;
                filterClone.locationId = undefined;
                filterClone.zoneId = undefined;
            }
            getInfoByClient({ companyId: 0 });
            setIsContinueUsingPermit(false);
        }
    }, [isContinueUsingPermit]);

    const getCompanies = ({ isSelectCompanyFirst }: { isSelectCompanyFirst?: boolean }) => {
        setPartialLoading({ isLoadingCompany: true });
        companiesController
            .getMyCompanyRF()
            .then((res) => {
                setPartialInitData({ companies: res });
                setPartialData({ companies: res });
                if (isSelectCompanyFirst) {
                    setACSelectedField('company');
                }
            })
            .finally(() => setPartialLoading({ isLoadingCompany: false }));
    };

    const getInfoByClient = ({
        companyId,
        isSetInitData,
        isSelectCompanyFirst,
    }: {
        companyId: number;
        isSetInitData?: boolean;
        isSelectCompanyFirst?: boolean;
    }) => {
        setPartialLoading({ isLoadingLocation: true });
        locationController
            .getAllRF({ ...defaultLocationFilter, filter: { companyId: companyId, regionId: 0 } })
            .then((res) => {
                setPartialData({ locations: res.data });
                isSetInitData && setPartialInitData({ locations: res.data });
                if (isSelectCompanyFirst) {
                    setPartialData({ locations: res.data.filter((l) => l.companyId === filterState.companyId) });
                }
            })
            .finally(() => setPartialLoading({ isLoadingLocation: false }));
    };

    const getAllocatedZonesByShop = async () => {
        await shopController.getLocationByShop(account.shopId!).then(async (res) => {
            // setLocations([res]);
            setTenantLocationId(res.id);
            setPartialLoading({ isLoadingZone: true });
            await zoneController
                .getZonesDetail({
                    locationId: 0,
                    shopId: account.shopId!,
                    permitId: isEdit ? Number(idPermit) : 0,
                    isTenant: true,
                })
                .then((res) => {
                    const name = res.map((item) => item.publicName).join(' ⅋ ');
                    const fakeZone = { id: 0, publicName: name, name: name } as Zone;
                    setPartialData({ zones: [fakeZone] });
                    setPartialInitData({ zones: res });
                    setPartialFilter({ zoneId: 0 });
                })
                .finally(() => setPartialLoading({ isLoadingZone: false }));
        });
    };

    const getShopByLocation = ({
        locationId,
        isSetInit,
        isSetShop: isSetTenant,
    }: {
        locationId: number;
        isSetInit?: boolean;
        isSetShop?: boolean;
    }) => {
        setPartialLoading({ isLoadingShop: true });
        shopController
            .filter({ ...defaultTenantFilter, filter: { locationId } })
            .then((shopPagi) => {
                if (shopPagi.data.length) {
                    setPartialData({ shops: shopPagi.data });
                    if (isSetInit) {
                        setPartialInitData({ shops: shopPagi.data });
                    }
                } else {
                    // openPopup
                    if (isFromStel && !isGoldRole(account.label)) stateDialog.handleOpen();
                    setPartialData({ shops: [] });
                }
            })
            .finally(() => setPartialLoading({ isLoadingShop: false }));
    };

    const handleChangeCompany = (company?: Company) => {
        if (isEdit || isFromStel) return;

        setPartialFilter({ companyId: company?.id, locationId: undefined, zoneId: undefined, shopId: undefined });
        if (company) {
            setACSelectedField('company');
            setPartialData({
                locations: initData.locations.filter((l) => l.companyId === company?.id ?? 0),
                shops: [],
                zones: [],
            });
        } else {
            setACSelectedField(undefined);
            setPartialData({
                locations: initData.locations,
                shops: [],
                zones: [],
            });
        }
    };

    const getDisableLocationSelect = () => {
        if (isEdit || isFromStel) return true;
        if (loading.isLoadingLocation) return true;
        if (isBronzeRole()) return true;
        if (!dataState.locations.length) return true;
        return false;
    };

    const handleChangeLocation = (location?: LocationWithDetail) => {
        if (isEdit || isFromStel) return;

        setPartialFilter({ locationId: location?.id, zoneId: undefined, shopId: undefined });
        setPartialData({ shops: [], zones: [] });

        if (ACSelectedField === 'company') {
            if (location) {
                //get tenant and zone
                getShopByLocation({ locationId: location.id });
                getMyZones({ locationId: location.id, shopId: 0, isSetInit: true });
            }
        } else {
            if (location) {
                setACSelectedField('location');
                setPartialFilter({
                    companyId: location.companyId,
                });
                getShopByLocation({ locationId: location.id });
                getMyZones({ locationId: location.id, shopId: 0, isSetInit: true });

                //getTenant and zone
            } else {
                setACSelectedField(undefined);
                setPartialFilter({
                    companyId: undefined,
                });
            }
        }
    };

    const getMyZones = ({
        locationId,
        shopId,
        isSetInit,
        isSetZone,
    }: {
        locationId: number;
        shopId: number;
        isSetInit?: boolean;
        isSetZone?: boolean;
    }) => {
        setPartialLoading({ isLoadingZone: true });
        zoneController
            .getZonesRF({
                locationId: locationId,
                permitId: 0,
                shopId: shopId,
            })
            .then((zones) => {
                setPartialData({ zones });
                if (isSetInit) {
                    setPartialInitData({ zones });
                }

                if (shopId !== 0) {
                    setSelectedField('tenant');
                }

                if (isSetZone) {
                    setSelectedField('zone');
                    const isZoneValid = zones.find((z) => z.id === Number(oldParams.zoneId));
                    isZoneValid && setPartialFilter({ zoneId: Number(oldParams.zoneId) });
                    isZoneValid && getShopByZoneIds(Number(oldParams.zoneId));
                }
            })
            .finally(() => setPartialLoading({ isLoadingZone: false }));
    };

    const getDisableShopSelect = () => {
        if (isEdit) return true;
        if (!dataState.shops.length) return true;
        // if (!(dataState.shops.length && !!filterState.locationId && !loading.isLoadingShop)) return true;
        return false;
    };

    const handleChangeTenant = (tenant?: Shop) => {
        if (isEdit) return;
        if ([Role.Tenant].includes(account.label)) return;

        setPartialFilter({ shopId: tenant?.id });

        if (selectedField === 'zone') {
        } else {
            setPartialFilter({ zoneId: undefined });
            if (tenant) {
                setSelectedField('tenant');
                getMyZones({ locationId: filterState.locationId ?? 0, shopId: tenant.id ?? 0, isSetInit: true });
            } else {
                setSelectedField(undefined);
                getMyZones({ locationId: filterState.locationId ?? 0, shopId: 0, isSetInit: true });
            }
        }
    };

    const getDisableZoneSelect = () => {
        if (isEdit || oldParams.zoneId !== null) return true;
        if (!filterState.zoneId && !filterState.locationId) return true;
        if (loading.isLoadingZone) return true;
        if (!dataState.zones.length && !filterState.zoneId && !isFromStel) return true;
        // if (isFromStel && !!oldParams.zoneId) return true;
        // if (isFromStel && !!stelParams.idZone) return true;
        if ([Role.Tenant].includes(account.label)) return true;
        return false;
    };

    const getZoneAutoCompName = () => {
        if (isTenant && dataState.zones.length && dataState.zones[0].id === 0) return t('permits:input.AllZones');
        if (isTenant) return t('companies:text.zones');
        return t('dashboard:input.zone');
    };

    const handleChangeZone = (zone?: Zone) => {
        if (isEdit) return;
        setPartialFilter({ zoneId: zone?.id });

        if (selectedField === 'tenant') {
        } else {
            setPartialFilter({ shopId: undefined });
            if (zone) {
                setSelectedField('zone');
                getShopByZoneIds(zone.id);
            } else {
                setSelectedField(undefined);
                getShopByLocation({ locationId: filterState.locationId ?? 0, isSetInit: true });
            }
        }
    };

    const getShopByZoneIds = (zoneId: number) => {
        setPartialLoading({ isLoadingShop: true });
        shopController
            .getShopByZoneIds([zoneId])
            .then((res) => {
                setPartialData({ shops: res });
            })
            .finally(() => {
                setPartialLoading({ isLoadingShop: false });
            });
    };

    return (
        <Grid item container spacing={2}>
            {isGoldRole(account.label) && (
                <Grid item xs={6} display={isBronzeRole() ? 'none' : 'block'}>
                    {/* {companySelect.RenderSelect} */}
                    <IAutoCompleteRF
                        listData={dataState.companies}
                        itemValue={dataState.companies.find((c) => c.id === filterState.companyId)}
                        label={'Company'}
                        keyLabel={'displayName'}
                        keyEqual={'id'}
                        setFilter={handleChangeCompany}
                        isHaveAllOptions={true}
                        allOptionLabel={'All companies'}
                        isLoading={loading.isLoadingCompany}
                        disabled={
                            isEdit ||
                            isFromStel ||
                            loading.isLoadingCompany ||
                            !dataState.companies.length ||
                            ACSelectedField === 'location'
                        }
                    />
                </Grid>
            )}
            <Grid item xs={isSilverRole(account.label) ? 12 : 6} display={isBronzeRole() ? 'none' : 'block'}>
                <IAutoCompleteLocation
                    listData={dataState.locations}
                    itemValue={dataState.locations.find((l) => l.id === filterState.locationId)}
                    label={t('dashboard:input.location')}
                    keyLabel={'name'}
                    keyEqual={'id'}
                    setFilter={handleChangeLocation}
                    isHaveAllOptions={true}
                    allOptionLabel={t('permits:input.AllLocations')}
                    isLoading={loading.isLoadingLocation}
                    disabled={getDisableLocationSelect()}
                    keyStatus="locationStatus"
                />
            </Grid>
            <Grid item xs={6} display={isBronzeRole() ? 'none' : 'block'}>
                <IAutoCompleteRF
                    listData={dataState.shops}
                    itemValue={dataState.shops.find((t) => t.id === filterState.shopId)}
                    label={t('permits:text.tenant')}
                    keyLabel={'tenantName'}
                    keyEqual={'id'}
                    setFilter={handleChangeTenant}
                    isHaveAllOptions={true}
                    allOptionLabel={t('permits:input.selectTenant')}
                    isLoading={loading.isLoadingShop}
                    disabled={getDisableShopSelect()}
                    // subDescLabels={['email']}
                />
            </Grid>
            <Grid item xs={isBronzeRole() ? 12 : 6}>
                <IAutoCompleteRF
                    listData={dataState.zones}
                    itemValue={dataState.zones.find((z) => z.id === filterState.zoneId)}
                    label={getZoneAutoCompName()}
                    keyLabel={'publicName'}
                    keyEqual={'id'}
                    setFilter={handleChangeZone}
                    isHaveAllOptions={true}
                    allOptionLabel={t('permits:input.AllZones')}
                    isLoading={loading.isLoadingZone}
                    disabled={getDisableZoneSelect()}
                />
            </Grid>
        </Grid>
    );
}
