import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import ScrollDialog from '../../../../../../components/ScrollDialog';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { shopController } from '../../../../../../controllers';
import { TenantContext } from '../../../../../../hooks/useTenants';
import { Shop } from '../../../../../../models/tenant/Shop';
import UpsertUserOfTenant from './AddUserOfTenant';

type Props = {
    open: boolean;
};

export default function ModalUpsertUserOfTenant(props: Props) {
    const { t } = useContext(LanguageContext);
    const { userFormData, shopSelect, modalUpsertUser, handleResetDataTenant, tenantFilterState } =
        useContext(TenantContext);
    const { handleClose, handleOpen } = modalUpsertUser;
    const [loadingSendMail, setLoadingSendMail] = useState(false);
    const {
        formState: { isValid },
        setError,
        reset,
        watch,
    } = userFormData;

    const isEdit = watch().id !== 0;
    const handleSubmitForm = async (isSendMail: boolean) => {
        const upsertShop: Shop = { ...shopSelect, isSendMail: isSendMail };

        upsertShop.clientUpsert = {
            email: watch().email,
            firstName: watch().firstName,
            accountId: watch().accountId,
            id: watch().id,
            lastName: watch().lastName,
            phoneNumber: watch().phoneNumber,
            isEnableITicketLite: watch().isEnableITicketLite,
        };

        //backend requirement update shopId to 0 when update client
        upsertShop.shopZones?.forEach((item) => (item.id = 0));

        setLoadingSendMail(true);
        shopController
            .upsert(upsertShop)
            .then(() => {
                //close popup
                modalUpsertUser.handleClose();
                //refresh tenant
                handleResetDataTenant({ page: tenantFilterState.page });
                //popup success
                !isEdit
                    ? toast.success('New user has been added successfully')
                    : toast.success('User update successful');
            })
            .catch((err) => {
                if (err?.response?.status === 409) {
                    setError('email', { message: t('validation:emailIsAlreadyRegistered') });
                }
                if (err?.response?.status !== 500) toast.error(err?.response?.data?.value);
            })
            .finally(() => {
                setLoadingSendMail(false);
            });

        // setShopSelect({} as Shop);
    };

    const handleCloseModel = () => {
        reset();
        handleClose();
    };

    return (
        <ScrollDialog
            open={props.open}
            handleOpen={handleOpen}
            handleClose={handleCloseModel}
            dialogTitle={
                !isEdit ? (
                    <Typography variant="h4" textAlign={'center'}>
                        Add user for tenant:{' '}
                        <Typography component={'span'} variant="h4" sx={{ color: color.priText }}>
                            {shopSelect.tenantName ?? ''}
                        </Typography>
                    </Typography>
                ) : (
                    `Edit user`
                )
            }
            dialogContent={
                <Box mt={3}>
                    <UpsertUserOfTenant formData={userFormData} />
                </Box>
            }
            dialogActions={
                <Grid item xs={12} display="flex" flexDirection={'row'} mt={1} justifyContent="space-between">
                    <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={handleCloseModel}>
                        {t('action:cancel')}
                    </Button>

                    <Stack direction={'row'} spacing={2}>
                        <LoadingButton
                            loading={loadingSendMail}
                            startIcon={<></>}
                            loadingPosition="start"
                            variant="outlined"
                            disabled={!isValid}
                            sx={{ minWidth: '150px' }}
                            type="submit"
                            onClick={() => handleSubmitForm(false)}
                        >
                            {t('action:saveOnly')}
                        </LoadingButton>

                        <LoadingButton
                            loading={loadingSendMail}
                            startIcon={<></>}
                            loadingPosition="start"
                            variant="contained"
                            disabled={!isValid}
                            sx={{ minWidth: '150px' }}
                            type="submit"
                            onClick={() => handleSubmitForm(true)}
                        >
                            {isEdit ? t('action:saveWithResend') : t('action:saveAndSend')}
                        </LoadingButton>
                    </Stack>
                </Grid>
            }
        />
    );
}
