import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Grid, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import BaseCheckbox from '../../../../../../components/BaseCheckbox';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import ScrollDialog from '../../../../../../components/ScrollDialog';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { shopController } from '../../../../../../controllers';
import useYupSchema from '../../../../../../helpers/useYupSchema';
import { LocationsContext } from '../../../../../../hooks/useLocations';
import { TenantContext } from '../../../../../../hooks/useTenants';
import Zone from '../../../../../../models/Zone';
import { AllocatedPermitType, AllocatedType } from '../../../../../../models/tenant/AllocatedTypeWithAmount';
import { ShopZone, Client, Shop } from '../../../../../../models/tenant/Shop';
import { BAY_INFO_LIMIT_CHARACTER } from '../../../../../permitsLayout/addEditPermit/components/UpsertPermitItem/UpsertPermitItemForm';
import AllocatedTypeItem, { DurationOptions } from '../AllocatedTypeItem';
import UpsertUserOfTenant from './AddUserOfTenant';
import theme from '../../../../../../theme/Theme';
import IOSSwitch from '../../../../../../components/IOSSwitch';
import { getDurationType, getDurationValue } from '../../helpers/TenantHelpers';
import { log } from 'console';

type Props = {
    handleClose: () => void;
    handleOpenModel: () => void;
    tenant: FormData;
    isEdit: boolean;
    open: boolean;
};
export default function ModelUpsertTenant({ isEdit, ...props }: Props) {
    const { getLocationDetail } = useContext(LocationsContext);
    const { zoneDataState, locationSelect } = useContext(LocationsContext);
    const { zones, zonesSelect } = zoneDataState;
    const { handleResetDataTenant, setShopSelect } = useContext(TenantContext);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [zoneCheckedSelect, setZoneCheckedSelect] = useState<Zone[]>(zones);
    const [allChecked, setAllChecked] = useState(true);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useContext(LanguageContext);

    const { yupValidateUser, yupValidateShop } = useYupSchema();

    useEffect(() => {
        if (isEdit) {
            let zonesEdit: Zone[] = [];
            if (props.tenant.zoneIds?.length) {
                zones.forEach((item) => {
                    if (props.tenant.zoneIds?.includes(item.id)) {
                        zonesEdit.push(item);
                    }
                });
                setAllChecked(false);
            } else {
                zonesEdit = zones;
                setAllChecked(true);
            }
            setZoneCheckedSelect(zonesEdit);
        }
    }, []);

    const [isFocused, setIsFocused] = useState(false);

    const formData = useForm<FormData>({
        mode: 'all',
        defaultValues: {
            ...props.tenant,
            allocatedTypes: isEdit ? props.tenant.allocatedTypes : allocatedTypesDefault,
            isAddUser: false,
            bayInfo: props.tenant.bayInfo ?? '',
            isEditBayInfo: props.tenant.isEditBayInfo ?? true,
            managerName: props.tenant.managerName ?? '',
            tenantName: props.tenant.tenantName ?? '',
            zoneIds: props.tenant.zoneIds ?? [],
        },
        resolver: yupResolver(yupValidateShop),
    });

    const {
        control,
        formState: { errors, isValid, isDirty },
        setError,
        setValue,
        watch,
    } = formData;

    const useFields = useFieldArray({
        name: 'allocatedTypes',
        control,
    });
    const { fields } = useFields;

    const userFormData = useForm<Client>({
        mode: 'all',
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            isEnableITicketLite: true,
            id: 0,
            accountId: 0,
        },
        resolver: yupResolver(yupValidateUser),
    });

    const handleSubmitForm = (isSendMail: boolean) => {
        const {
            id,
            managerName,
            tenantName,
            bayInfo,
            isEditBayInfo,
            allocatedTypes,
            isAddUser,
            isLockScreenPIN,
            isEnableIhubSupportFeature,
            pin,
            isRevealPermit,
        } = watch();

        const allocatedPermits: ShopZone[] =
            zoneCheckedSelect.length === zonesSelect!.length
                ? []
                : zoneCheckedSelect.map((item) => {
                      return {
                          shopId: id,
                          zoneId: item.id,
                          allocatedAmount: 0,
                          id: 0,
                          isUnlimited: false,
                      };
                  });

        const tenant: Shop = {
            ...props.tenant,
            // email,
            managerName,
            tenantName,
            locationId: locationSelect?.id,
            locationName: locationSelect?.name,
            isSendMail,
            // isVerify: isSendMail,
            shopZones: allocatedPermits,
            isEditBayInfo,
            bayInfo,
            isLockScreenPIN,
            pin: isLockScreenPIN ? pin : null,
            isRevealPermit,
            isEnableIhubSupportFeature,
            allocatedTypes: allocatedTypes?.map((at) => {
                const isTemporary = at.allocatedPermitType === AllocatedPermitType.Temporary;
                console.log('at', at);

                return {
                    ...at,
                    locationId: locationSelect!.id,
                    amount: at.isActive || at.allocatedPermitType === AllocatedPermitType.Once ? at.amount : 0,
                    durationType: isTemporary ? getDurationType(at.durationOptions! as DurationOptions) : 0,
                    duration: isTemporary
                        ? getDurationValue(at.durationOptions! as DurationOptions, at.monthValue, at.dayValue)
                        : 0,
                    isUnlimited: at.isUnlimited,
                };
            }),
            id: props.tenant.id,
        };

        if (!isEdit && isAddUser) {
            tenant.clientUpsert = userFormData.watch();
        }

        console.log('tenant', tenant);

        setLoading(true);
        shopController
            .upsert(tenant)
            .then((res) => {
                //close popup
                props.handleClose();
                //refresh tenant
                handleResetDataTenant();
                //refresh location detail
                getLocationDetail();
                //popup success
                !isEdit
                    ? toast.success('New tenant has been added successfully')
                    : toast.success('Tenant update successful');
            })
            .catch((err) => {
                if (err?.response?.status === 409) {
                    userFormData.setError('email', { message: t('validation:emailIsAlreadyRegistered') });
                }
                if (err?.response?.status !== 500) toast.error(err?.response?.data?.value);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCheckedZone = (zone: Zone) => {
        let zoneCheckedSelectClone = [...zoneCheckedSelect];
        const item = zoneCheckedSelectClone.find((item) => item.id === zone.id);

        if (item) {
            zoneCheckedSelectClone = zoneCheckedSelectClone.filter((item) => item.id !== zone.id);
        } else {
            zoneCheckedSelectClone.push(zone);
        }
        setZoneCheckedSelect(zoneCheckedSelectClone);
        setAllChecked(zoneCheckedSelectClone.length === zones.length);
    };

    const handleChangeCheckedAll = (checked: boolean) => {
        setZoneCheckedSelect(checked ? zones : []);
    };

    const isZoneScopeValid = zoneCheckedSelect.length > 0;
    const isAllPermitTypeValid = watch().allocatedTypes?.every((item) => item.isValid === true);

    const isAddUserFormValid = watch().isAddUser ? userFormData.formState.isValid : true;

    const handleCloseModal = () => {
        formData.reset({});
        setShopSelect({} as Shop);
        props.handleClose();
    };

    return (
        <ScrollDialog
            open={props.open}
            handleOpen={props.handleOpenModel}
            fullScreen={fullScreen}
            handleClose={handleCloseModal}
            dialogTitle={isEdit ? t('companies:tenants.editTenant') : t('companies:tenants.addTenant')}
            dialogContent={
                <Grid
                    item
                    container
                    xs={12}
                    mt={3}
                    sx={{
                        '& .MuiFormHelperText-root': {
                            fontSize: '12px',
                            backgroundColor: color.grey100,
                        },
                    }}
                    key={3}
                >
                    <Grid item container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'tenantName'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.tenantName?.message}
                                        helperText={errors.tenantName?.message as any}
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:tenants.tenantName')} />}
                                        placeholder={t('input:enterFullName')}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'managerName'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.managerName?.message}
                                        helperText={errors.managerName?.message as any}
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:tenants.managerName')} />}
                                        placeholder={t('input:enterManagerName')}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Permit:</Typography>
                            <Stack sx={{ borderRadius: '5px', background: color.grey100 }} p={2} gap={2}>
                                {fields.map((field, index) => {
                                    return (
                                        <AllocatedTypeItem
                                            fieldData={field}
                                            key={field.id}
                                            formIndex={index}
                                            useFields={useFields}
                                            formData={formData}
                                            isEdit={isEdit}
                                        />
                                    );
                                })}
                            </Stack>
                        </Grid>

                        <Grid item xs={12} wrap="nowrap">
                            <Typography>Zone & Bay information</Typography>
                            <Stack sx={{ borderRadius: '5px', background: color.grey100 }} p={2} gap={2}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography>{t('companies:text.zones')}</Typography>
                                    <BaseCheckbox
                                        label={<Typography sx={{ ml: 1 }}>{t('permits:input.AllZones')}</Typography>}
                                        checkBoxProps={{
                                            checked: allChecked,
                                            onChange(event, checked) {
                                                setAllChecked(checked);
                                                handleChangeCheckedAll(checked);
                                                // popUpWarning.setTrue();
                                            },
                                            disabled: false,
                                        }}
                                    />
                                </Stack>
                                <Box mt={0.5}>
                                    <Stack sx={{ background: color.white, padding: '12px 16px' }}>
                                        <Grid
                                            container
                                            display={'flex'}
                                            flexDirection={{ xs: 'column', sm: 'row' }}
                                            flexWrap="wrap"
                                            spacing={2}
                                        >
                                            {zones.map((item) => {
                                                return (
                                                    <Grid item xs={4} wrap="nowrap" zeroMinWidth>
                                                        <BaseCheckbox
                                                            key={item.id}
                                                            label={
                                                                <Tooltip
                                                                    arrow
                                                                    placement="top"
                                                                    title={`${item.publicName}`}
                                                                    PopperProps={{ style: { zIndex: 99999 } }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            ml: 1,
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            maxWidth: { xs: '100%', sm: '150px' },
                                                                        }}
                                                                    >
                                                                        {item.publicName}
                                                                    </Typography>
                                                                </Tooltip>
                                                            }
                                                            checkBoxProps={{
                                                                checked: zoneCheckedSelect.includes(item),
                                                                onChange(event, checked) {
                                                                    handleCheckedZone(item);
                                                                    // popUpWarning.setTrue();
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Stack>
                                </Box>
                                <Divider variant="middle" />
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack justifyContent={'center'}>
                                        <Typography variant="body1">Bay information:</Typography>
                                    </Stack>
                                    <Stack direction="column" alignItems={'flex-end'}>
                                        <Controller
                                            name="isEditBayInfo"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <BaseCheckbox
                                                        label={
                                                            <Typography sx={{ ml: 1 }}>Allow tenant to edit</Typography>
                                                        }
                                                        checkBoxProps={{
                                                            checked: field.value,
                                                            onChange(event, checked) {
                                                                // popUpWarning.setTrue();
                                                                field.onChange(checked);
                                                            },
                                                            // disabled: false,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />

                                        <Typography sx={{ fontSize: '10px', color: color.grey600 }}>
                                            {watch().isEditBayInfo
                                                ? 'Allow tenant to edit'
                                                : 'Do not allow tenant to edit'}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Box
                                        sx={{
                                            p: '12px 16px',
                                            background: color.white,
                                            '& .MuiFormHelperText-root': {
                                                position: 'absolute',
                                                top: '35px',
                                                right: '-10px !important',
                                                left: 'auto',
                                            },
                                        }}
                                    >
                                        <Controller
                                            control={control}
                                            name={'bayInfo'}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    onFocus={() => setIsFocused(true)}
                                                    onBlur={() => setIsFocused(false)}
                                                    fullWidth
                                                    // placeholder={t('permits:text.enterBayNumber')}
                                                    helperText={
                                                        isFocused &&
                                                        `${field.value?.length ?? 0}/${BAY_INFO_LIMIT_CHARACTER}`
                                                    }
                                                    onChange={(e) => field.onChange(e)}
                                                    inputProps={{
                                                        maxLength: 40,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>Lock Screen PIN</Typography>
                                    <Typography color={color.grey600}>
                                        You have the option to configure a PIN specific to the tenant to activate the
                                        screen lock on iPark devices.
                                    </Typography>
                                </Box>
                                <Controller
                                    name="isLockScreenPIN"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                    // setValue('pin', 0);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>

                            <Stack
                                sx={{
                                    borderRadius: '5px',
                                    background: color.grey100,
                                    display: watch().isLockScreenPIN ? 'block' : 'none',
                                }}
                                p={2}
                                gap={2}
                            >
                                <Controller
                                    control={control}
                                    name={'pin'}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!errors.pin?.message}
                                            helperText={errors.pin?.message as any}
                                            placeholder={'Enter your PIN'}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/g.test(value)) {
                                                    field.onChange(value);
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>Reveal permit expiry date</Typography>
                                </Box>
                                <Controller
                                    name="isRevealPermit"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                    // setValue('pin', 0);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>{t('companies:tenants.enableIHubSupportFeature')}</Typography>
                                </Box>
                                <Controller
                                    name="isEnableIhubSupportFeature"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                    // setValue('pin', 0);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>

                        {!isEdit && (
                            <Grid item xs={12} sx={{ mb: watch().isAddUser ? 0 : 2 }}>
                                <Controller
                                    control={control}
                                    name={`isAddUser`}
                                    render={({ field }) => (
                                        <BaseCheckbox
                                            {...field}
                                            label={
                                                <Typography sx={{ ml: 1 }}>
                                                    Click on the checkbox to add a new user to the tenant.
                                                </Typography>
                                            }
                                            checkBoxProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(checked);
                                                },
                                                disabled: false,
                                            }}
                                        />
                                    )}
                                />
                                {watch().isAddUser && (
                                    <Stack sx={{ borderRadius: '5px', background: color.grey100 }} mt={1} p={2} mb={2}>
                                        <Box>
                                            <UpsertUserOfTenant formData={userFormData} />
                                        </Box>
                                    </Stack>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            }
            dialogActions={
                <Grid item xs={12} display="flex" flexDirection={'row'} mt={1} justifyContent="space-between">
                    <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={props.handleClose}>
                        {t('action:cancel')}
                    </Button>

                    {watch().isAddUser ? (
                        <Stack direction={'row'} spacing={2}>
                            <LoadingButton
                                loading={isLoading}
                                startIcon={<></>}
                                loadingPosition="start"
                                variant="outlined"
                                disabled={!isValid || !isZoneScopeValid || !isAllPermitTypeValid || !isAddUserFormValid}
                                sx={{ minWidth: '150px' }}
                                type="submit"
                                onClick={() => handleSubmitForm(false)}
                            >
                                {t('action:saveOnly')}
                            </LoadingButton>

                            <LoadingButton
                                loading={isLoading}
                                startIcon={<></>}
                                loadingPosition="start"
                                variant="contained"
                                disabled={!isValid || !isZoneScopeValid || !isAllPermitTypeValid || !isAddUserFormValid}
                                sx={{ minWidth: '150px' }}
                                type="submit"
                                onClick={() => handleSubmitForm(true)}
                            >
                                {isEdit ? t('action:saveWithResend') : t('action:saveAndSend')}
                            </LoadingButton>
                        </Stack>
                    ) : (
                        <LoadingButton
                            loading={isLoading}
                            startIcon={<></>}
                            loadingPosition="start"
                            variant="contained"
                            disabled={!isValid || !isZoneScopeValid || !isAllPermitTypeValid || !isAddUserFormValid}
                            sx={{ minWidth: '150px' }}
                            type="submit"
                            onClick={() => handleSubmitForm(false)}
                        >
                            {t('action:save')}
                        </LoadingButton>
                    )}
                </Grid>
            }
        />
    );
}

const allocatedTypesDefault = [
    AllocatedPermitType.Once,
    AllocatedPermitType.Dynamic,
    AllocatedPermitType.Indefinite,
    AllocatedPermitType.Temporary,
].map((allocatedPermitType) => {
    const isOnce = allocatedPermitType === AllocatedPermitType.Once;
    return {
        isValid: false,
        amount: allocatedPermitType === AllocatedPermitType.Once ? 1 : 0,
        allocatedPermitType,
        clientId: 0,
        isActive: true,
        locationId: 0,
        usedPermit: 0,
        isUnlimited: isOnce ? true : false,
        monthValue: 0,
        dayValue: 0,
        durationOptions: DurationOptions.OneMonth,
    } as AllocatedType;
});

export interface ZoneValid {
    id: number;
    isValid: boolean;
}

export interface ItemChecked {
    id: number;
    checked: boolean;
}

export interface FormData {
    id: number;
    tenantName: string;
    managerName: string;

    bayInfo?: string;
    isEditBayInfo?: boolean;
    zoneIds?: number[];
    allocatedTypes?: AllocatedType[];
    locationId?: number;

    isAddUser?: boolean;
    isLockScreenPIN: boolean;
    isRevealPermit: boolean;
    isEnableIhubSupportFeature: boolean;
    pin: string;
}

export interface ZoneTenantItem {
    isChecked: boolean;
    totalPermits: number;
    remaining: number;
    currentRemaining: number;
    inputPermit: number | undefined | string;
    zone?: Zone;
    usedPermit: number;
    allocatedAmount: number;
    isEdit: boolean;
    greaterThan: number;
}
