import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography } from '@mui/material';

type Props = {
    dialogTitle: React.ReactNode | string;
    dialogContent: React.ReactNode;
    dialogActions?: React.ReactNode;
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    fullScreen?: boolean;
};

export default function ScrollDialog(props: Props) {
    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullScreen={props.fullScreen}
                scroll={'paper'}
                sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
            >
                <DialogTitle id="scroll-dialog-title" sx={{ padding: `24px 24px 0 24px` }}>
                    {typeof props.dialogTitle === 'string' ? (
                        <Typography variant="h4" textAlign={'center'}>
                            {props.dialogTitle}
                        </Typography>
                    ) : (
                        props.dialogTitle
                    )}
                </DialogTitle>
                <DialogContent dividers={false} sx={{ p: `0 24px` }}>
                    <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                        {props.dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: `8px 24px 24px 24px` }}>
                    {props.dialogActions ?? (
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                            <Button onClick={props.handleClose}>Cancel</Button>
                            <Button onClick={props.handleClose}>Subscribe</Button>
                        </Stack>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
