import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import color from '../config/Colors';
import LogoIconWithName from './LogoIconWithName';
import { Avatar, Stack, Typography } from '@mui/material';
import LanguageSelect from './LanguageSelect';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AuthContext } from '../hooks/useAuth';
import { DrawerContext } from '../pages/dashboardLayout/useDrawer';
import LogoutIcon from '../assets/images/UserMenuIcon';
import { appConfig } from '../config/AppConfig';
import FeedbackForm from '../pages/feedback/components/FeedbackForm';
import { Role } from '../models/permission/Role';

type Props = {
    // changeIsShowDrawer: () => void;
    isShowLabelAccount: boolean;
    isShowLabelLanguage?: boolean;
};
export const ZIndexHeader = 1200;

export default function MyAppBar(props: Props) {
    const classes = style();
    const { isShowLabelLanguage = true } = props;
    const { account, userAvatar } = useContext(AuthContext);
    const isShowMultiLanguage = appConfig.flagFeatures.isShowMultiLanguage;
    const { setOpenDrawerSmall, isMobileSM } = useContext(DrawerContext);

    const isAccountManager = [Role.AccountManager].includes(account.label);

    return (
        <AppBar
            position="static"
            sx={{ position: 'fixed', zIndex: ZIndexHeader, width: '100vw', top: 0, background: color.appBarColor }}
        >
            <Toolbar>
                <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Stack flexDirection={'row'}>
                        {isMobileSM ? (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => setOpenDrawerSmall(true)}
                            >
                                <MenuIcon style={{ color: color.white }} />
                            </IconButton>
                        ) : (
                            ''
                        )}
                        <LogoIconWithName />
                    </Stack>

                    {
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={2}
                            className={classes.styleLanguageTop}
                            sx={{
                                display: isMobileSM ? 'none' : undefined,
                                '& .MuiSvgIcon-root': {
                                    color: color.grey600,
                                    px: '5px',
                                },
                                '& .MuiInputBase-input': {
                                    pr: '35px !important',
                                },
                            }}
                        >
                            {props.isShowLabelAccount && !isAccountManager && <FeedbackForm />}
                            {isShowMultiLanguage && isShowLabelLanguage && <LanguageSelect isShowLabel={false} />}
                            {props.isShowLabelAccount && (
                                <Box>
                                    <Typography
                                        variant="body1"
                                        align="center"
                                        color={color.white}
                                        display={'inline-block'}
                                        minWidth={80}
                                    >
                                        {account ? account?.firstName + ' ' + account?.lastName : 'Admin'}
                                    </Typography>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        {userAvatar ? (
                                            <Avatar
                                                sx={{ width: '32px', height: '32px' }}
                                                alt={account?.firstName?.toUpperCase() || 'A'}
                                                src={userAvatar}
                                            />
                                        ) : (
                                            <LogoutIcon />
                                        )}
                                    </IconButton>
                                </Box>
                            )}
                        </Stack>
                    }
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

const style = makeStyles((theme: Theme) => ({
    styleLanguageTop: {
        '& .MuiSelect-select': {
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '3px',

            '& .MuiTypography-root': {
                display: 'none',
            },
            '&:focus': {
                background: 'rgba(255, 255, 255, 0.1) !important',
            },
        },
    },
}));
