import { Permit } from '../../../../models/permits/Permit';
import { PermitRecurring, PermitRecurringDay } from '../../../../models/permits/PermitRecurring';
import moment from 'moment';
import { AllocatedType, DurationType } from '../../../../models/tenant/AllocatedTypeWithAmount';

export const getDaysOfWeekRecur = (permitRecurringDays: PermitRecurringDay[]) => {
    const days: Date[] = [];
    if (permitRecurringDays.length > 0) {
        permitRecurringDays.map((item) => days.push(new Date(item.firstExecuteAt!)));
    } else {
        days.push(new Date());
    }
    return days;
};

export const getWeeksDay = (arr: PermitRecurringDay[]) => {
    const num: number[] = [];
    arr.forEach((item) => {
        num.push(moment(item.firstExecuteAt!).weekday());
    });
    return num;
};

export const getIsCheckedAllDay = (permitRecurring: PermitRecurring) => {
    if (permitRecurring.fromHour === 0 && [86400, 86340].includes(permitRecurring.toHour!)) {
        return true;
    } else {
        return false;
    }
};

export const getTemporaryDays = (temporaryDuration: AllocatedType) => {
    if (temporaryDuration.durationType === DurationType.Week) {
        return temporaryDuration.duration * 7;
    }

    if (temporaryDuration.durationType === DurationType.Month) {
        return temporaryDuration.duration * 31;
    }

    return temporaryDuration.duration;
};
