/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Typography } from '@mui/material';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { useLocation, useSearchParams } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import IAutoCompleteRF from '../components/autoComplete/IAutoComplete2';
import IMultiSelectWithCheckBoxRF from '../components/autoComplete/IMultiSelectWithCheckBoxRF';
import { appConfig } from '../config/AppConfig';
import color from '../config/Colors';
import { GROSS_REVENUE_CROSS_LOCATION_ID } from '../contexts/FilterContext';
import { LanguageContext } from '../contexts/useLanguage';
import { companiesController, locationController, regionController, zoneController } from '../controllers';
import { ConvertToGMT0Time } from '../helpers';
import { Company } from '../models/Company';
import { Filter } from '../models/Filter';
import { LocationWithDetail } from '../models/Location';
import { Region } from '../models/Region';
import { ReportType } from '../models/ReportType';
import Zone from '../models/Zone';
import useGetStatisticData from '../pages/permitsLayout/addEditPermit/hooks/useGetStatisticData';
import useDatePicker from './useDatePicker';
import moment from 'moment';
import IAutoCompleteLocation from '../components/autoComplete/IAutoCompleteLocation';

export interface LoadingFilter {
    isLoadingCompany: boolean;
    isLoadingLocation: boolean;
    isLoadingRegion?: boolean;
    isLoadingZone?: boolean;
    isLoadingShop?: boolean;
    isLoadingPermitType?: boolean;
    isLoadingPage?: boolean;
}

export const _initLoadingFilter: LoadingFilter = {
    isLoadingCompany: false,
    isLoadingLocation: false,
    isLoadingRegion: false,
    isLoadingZone: false,
    isLoadingPermitType: false,
    isLoadingShop: false,
    isLoadingPage: false,
};

export type FilterShowingPage = 'dashboard' | 'report';

export interface ReportFilterState {
    showingPage: FilterShowingPage;
    report: ReportType;
}

interface DataState {
    companies: Company[];
    regions: Region[];
    locations: LocationWithDetail[];
    zones: Zone[];
}

const _initDataState: DataState = {
    companies: [],
    locations: [],
    regions: [],
    zones: [],
};

interface FilterState {
    company?: Company;
    region?: Region;
    location?: LocationWithDetail;
    page?: number;
    zones?: Zone[];
    from?: Date;
    to?: Date;
}

interface FilterStateId {
    companyId?: number;
    regionId?: number;
    locationId?: number;
    page?: number;
    zoneIds?: number[];
    from?: Date;
    to?: Date;
}

export type SelectField = 'location' | 'company';

export enum ParamsFilterDashboard {
    company = 'companyDbId',
    region = 'regionDbId',
    location = 'locationDbId',
    zone = 'zoneDbIds',
    from = 'fromDb',
    to = 'toDb',
    page = 'pageDb',
}

export type ParamsDashboard = Partial<Record<ParamsFilterDashboard, string>>;

export default function useFilter() {
    const { t } = useContext(LanguageContext);

    const [selectField, setSelectField] = useState<SelectField>();
    const [loadingAPIs, setLoadingAPIs] = useState({ zone: true });
    const location = useLocation();
    const [isLoadData, setIsLoadData] = useState<boolean>(false);

    const [reportState, setReportState] = useState<ReportFilterState>({
        showingPage: 'dashboard',
        report: ReportType.none,
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        companyId: searchParams.get(ParamsFilterDashboard.company),
        locationId: searchParams.get(ParamsFilterDashboard.location),
        regionId: searchParams.get(ParamsFilterDashboard.region),
        zoneIds: searchParams.get(ParamsFilterDashboard.zone),
        from: searchParams.get(ParamsFilterDashboard.from),
        to: searchParams.get(ParamsFilterDashboard.to),
        page: searchParams.get(ParamsFilterDashboard.page),
    };

    const [loading, setLoading] = useState<LoadingFilter>(_initLoadingFilter);
    const setPartialLoading = (partial: Partial<LoadingFilter>) => setLoading((prev) => ({ ...prev, ...partial }));

    const [dataState, setDataState] = useState<DataState>(_initDataState);
    const setPartialData = (partial: Partial<DataState>) => setDataState((prev) => ({ ...prev, ...partial }));

    const [initData, setInitData] = useState<DataState>(_initDataState);
    const setInitPartialData = (partial: Partial<DataState>) => setInitData((prev) => ({ ...prev, ...partial }));

    const [filterState, setFilterState] = useState<FilterStateId>({
        companyId: !!params.companyId ? Number(params.companyId) : 0,
        locationId: !!params.locationId ? Number(params.locationId) : 0,
        regionId: !!params.regionId ? Number(params.regionId) : 0,
        page: !!params.page ? Number(params.page) : 1,
        zoneIds: !!params.zoneIds ? params.zoneIds.split(',').map(Number) : [],
        from: !!params.from ? startOfDay(new Date(params.from)) : startOfDay(subDays(new Date(), 7)),
        to: !!params.to ? endOfDay(new Date(params.to)) : endOfDay(addDays(new Date(), -1)),
    } as FilterStateId);

    const setPartialFilter = (partial: Partial<FilterStateId>) => setFilterState((prev) => ({ ...prev, ...partial }));

    const {
        chartData,
        averageLengthOfStay,
        getAverageLengthOfVisits,
        getCameraNotWorking,
        getCapacityAnalysis,
        getDailyVisits,
        getGrossRevenue,
        getGrossRevenueAmountLTs,
        getGrossRevenueByLongTerm,
        getGroupCancelledPCNByActor,
        getGroupPCNByReason,
        getGroupPCNByStatus,
        getGroupPCNByZone,
        getRepeatVisits,
        loadingRepeatVisit,
        getPCNsByServiceType,
    } = useGetStatisticData();

    //get data when is report Page or dashboard
    const isDashboardOrReport = location.pathname.includes('dashboard') || location.pathname.includes('reports');
    useEffect(() => {
        if (isDashboardOrReport) {
            setIsLoadData(true);
        } else {
            setIsLoadData(false);
        }
    }, [location]);

    useEffect(() => {
        isLoadData && initDataFunc();
    }, [isLoadData]);

    const initDataFunc = async () => {
        setPartialLoading({ isLoadingCompany: true, isLoadingLocation: true, isLoadingRegion: true });
        companiesController
            .getMyCompanyRF()
            .then((res) => {
                setPartialData({ companies: res });
                setInitPartialData({ companies: res });
            })
            .finally(() => setPartialLoading({ isLoadingCompany: false }));

        locationController
            .getAllRF({
                pageSize: 10000,
                page: 1,
                filter: { companyId: 0, regionId: 0 },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                setInitPartialData({ locations: res.data });
                if (!!params.companyId) {
                    setSelectField('company');
                    setPartialData({ locations: res.data.filter((l) => l.companyId === Number(params.companyId)) });
                } else {
                    setPartialData({ locations: res.data });
                }
            })
            .finally(() => setPartialLoading({ isLoadingLocation: false }));

        regionController
            .getAllRF({
                pageSize: 10000,
                page: 1,
                filter: { companyId: 0 },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                setPartialData({ regions: res.data });
                if (!!params.companyId) {
                    setPartialData({ regions: res.data.filter((r) => r.companyId === Number(params.companyId)) });
                } else setInitPartialData({ regions: res.data });
            })
            .finally(() => setPartialLoading({ isLoadingRegion: false }));

        if (params.zoneIds !== null) {
            // const _zoneIds = params.zoneIds?.split(',').map(Number);
            await zoneController
                .getZonesRF({
                    locationId: Number(params.locationId) ?? 0,
                    permitId: 0,
                    shopId: 0,
                })
                .then((zones) => setPartialData({ zones }))
                .finally(() => {
                    setPartialLoading({ isLoadingZone: false });
                });
        }
    };

    useEffect(() => {
        setLoadingAPIs((p) => ({ ...p, zone: true }));
        filterState.locationId && getMyZones();
        setLoadingAPIs((p) => ({ ...p, zone: false }));
    }, [filterState.locationId]);

    const getMyZones = async () => {
        setPartialLoading({ isLoadingZone: true });
        await zoneController
            .getZonesRF({
                locationId: filterState.locationId ?? 0,
                permitId: 0,
                shopId: 0,
            })
            .then((zones) => setPartialData({ zones }))
            .finally(() => {
                setPartialLoading({ isLoadingZone: false });
            });
    };

    const handleResetFilter = () => {
        setFilterState({
            from: startOfDay(subDays(new Date(), 7)),
            to: endOfDay(addDays(new Date(), -1)),
        });
        setPartialData({
            companies: initData.companies,
            regions: initData.regions,
            locations: initData.locations,
            zones: [],
        });
        setSelectField(undefined);
    };

    useEffect(() => {
        if (reportState.showingPage === 'dashboard' && !loadingAPIs.zone && isLoadData && !!filterState.locationId) {
            getCameraNotWorking(convertFilterRF());
        } else return;
    }, [filterState]);

    useEffect(() => {
        if (reportState.showingPage === 'dashboard' && !loadingAPIs.zone && isLoadData) {
            // convertToParams(filterState);

            const filterGMT0 = convertFilterRF();
            getDailyVisits(filterGMT0);
            getAverageLengthOfVisits(filterGMT0);
            getCapacityAnalysis(filterGMT0);
            getRepeatVisits(filterGMT0);
            if (appConfig.flagFeatures.isEnableReportPCNs) {
                getGroupPCNByStatus(filterGMT0);
                getGroupCancelledPCNByActor(filterGMT0);
                getGroupPCNByZone(filterGMT0);
                getGroupPCNByReason(filterGMT0);
                getPCNsByServiceType(filterGMT0);
            }
            if (filterState.locationId === GROSS_REVENUE_CROSS_LOCATION_ID) {
                getGrossRevenue(filterGMT0);
                getGrossRevenueByLongTerm(filterGMT0);
                getGrossRevenueAmountLTs(filterGMT0);
            }
        }
    }, [filterState, loadingAPIs.zone, isLoadData]);

    const { RenderDatePicker } = useDatePicker({
        startDate: filterState.from!,
        endDate: filterState.to!,
        onChangeTime(e) {
            setPartialFilter({ from: startOfDay(e[0]), to: endOfDay(e[1]) });
        },
    });

    useEffect(() => {
        handleChangeFilterToParams();
    }, [filterState, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});

        if (filterState.companyId) newParams.append(ParamsFilterDashboard.company, filterState.companyId.toString());
        if (filterState.locationId) newParams.append(ParamsFilterDashboard.location, filterState.locationId.toString());
        if (filterState.regionId) newParams.append(ParamsFilterDashboard.region, filterState.regionId.toString());
        if (filterState.zoneIds?.length) {
            const handlerIdsParam = filterState.zoneIds.map((item) => encodeURIComponent(item)).join(',');
            newParams.append(ParamsFilterDashboard.zone, handlerIdsParam);
        }
        if (filterState.from)
            newParams.append(ParamsFilterDashboard.from, ConvertToGMT0Time(filterState.from).toISOString());
        if (filterState.to) newParams.append(ParamsFilterDashboard.to, ConvertToGMT0Time(filterState.to).toISOString());
        if (filterState.page && filterState.page !== 1) {
            newParams.append(ParamsFilterDashboard.page, filterState.page.toString());
        }

        setSearchParams(newParams);
    };

    const handleChangeCompany = (company?: Company) => {
        setPartialFilter({ companyId: company?.id, regionId: undefined, locationId: undefined, zoneIds: [] });

        if (company) {
            setSelectField('company');
            setPartialData({
                locations: initData.locations.filter((l) => l.companyId === company?.id ?? 0),
                regions: initData.regions.filter((r) => r.companyId === company?.id ?? 0),
            });
        } else {
            setSelectField(undefined);
            setPartialData({ locations: initData.locations, regions: initData.regions });
        }
    };

    const handleChangeLocation = (location?: LocationWithDetail) => {
        setPartialFilter({ locationId: location?.id, zoneIds: [] });
        setPartialData({ zones: [] });

        if (selectField === 'company') {
            if (location) {
                //get tenant and zone
                setPartialFilter({ regionId: location.regionId });
            }
        } else {
            if (location) {
                setSelectField('location');
                setPartialFilter({
                    regionId: location.regionId,
                    companyId: location.companyId,
                });
                setPartialData({
                    regions: initData.regions.filter((r) => r.id === location?.regionId),
                    companies: initData.companies.filter((c) => c.id === location?.companyId),
                    zones: [],
                });
            } else {
                setSelectField(undefined);
                setPartialFilter({ locationId: undefined, zoneIds: [], regionId: undefined, companyId: undefined });
                setPartialData({
                    companies: initData.companies,
                    regions: initData.regions,
                    locations: initData.locations,
                });
            }
        }
    };

    const handleChangeRegion = (region?: Region) => {
        setPartialData({
            locations: initData.locations.filter((l) =>
                region ? l.regionId === region?.id : l.companyId === filterState.companyId
            ),
        });
        setPartialFilter({ regionId: region?.id, locationId: undefined, zoneIds: [], page: 1 });
    };

    const handleChangeZone = (zones?: Zone[]) => {
        setPartialFilter({ zoneIds: zones?.map((z) => z.id) });
    };

    useEffect(() => {
        if (reportState.showingPage === 'report' && isLoadData) {
            const filterGMT0 = convertFilterRF();
            if (reportState.report === ReportType.averageLength) {
                getAverageLengthOfVisits(filterGMT0);
            } else if ([ReportType.evAverageLength, ReportType.capacityAnalysis].includes(reportState.report)) {
                getCapacityAnalysis(filterGMT0);
            } else if (reportState.report === ReportType.repeatVisits) {
                getRepeatVisits(filterGMT0);
            } else if ([ReportType.dailyVisit, ReportType.evDailyVisit].includes(reportState.report)) {
                getDailyVisits(filterGMT0);
            } else if (reportState.report === ReportType.groupByPCNTypes) {
                getGroupPCNByStatus(filterGMT0);
            } else if (reportState.report === ReportType.groupByPCNCancellations) {
                getGroupCancelledPCNByActor(filterGMT0);
            } else if (reportState.report === ReportType.PCNByZones) {
                getGroupPCNByZone(filterGMT0);
            } else if (reportState.report === ReportType.PCNByReason) {
                getGroupPCNByReason(filterGMT0);
            } else if (reportState.report === ReportType.grossRevenueBySTs) {
                getGrossRevenue(filterGMT0);
            } else if (reportState.report === ReportType.activeLongTermBooking) {
                getGrossRevenueByLongTerm(filterGMT0);
            } else if (reportState.report === ReportType.grossRevenueByLTs) {
                getGrossRevenueAmountLTs(filterGMT0);
            } else if (reportState.report === ReportType.PCNsByServiceType) {
                getPCNsByServiceType(filterGMT0);
            }
        }
    }, [filterState, reportState.report, isLoadData]);

    const convertFilterRF = () => {
        const filterConvert: Filter = {
            companyId: filterState.companyId ?? 0,
            regionId: filterState.regionId ?? 0,
            locationId: filterState.locationId ?? 0,
            from: ConvertToGMT0Time(filterState.from!),
            to: ConvertToGMT0Time(filterState.to!),
            zoneIds: filterState.zoneIds ?? [],
        };
        return filterConvert;
    };

    const getZones = () => {
        const zones: Zone[] = [];
        filterState.zoneIds?.forEach((z) => {
            const find = dataState.zones.find((zone) => zone.id === z);
            if (find) zones.push(find);
        });
        return zones;
    };

    const FilterComponents = (
        <Grid
            item
            container
            xs={12}
            p={2}
            sx={{ background: color.grey100 }}
            borderRadius={'10px'}
            justifyContent={'space-between'}
        >
            <Grid
                item
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                <Typography variant="body1" component={'span'} mx={0.5}>
                    {t('dashboard:button.filter')}{' '}
                </Typography>
                <Button variant="cancelSmall" onClick={handleResetFilter}>
                    {t('dashboard:button.resetFilter')}
                </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={3} mt={{ xs: 1, sm: 0, md: 0 }}>
                {RenderDatePicker}
            </Grid>

            <Grid item xs={12} mt={{ xs: 1, sm: 2, md: 2 }}>
                <IAutoCompleteLocation
                    listData={dataState.locations}
                    itemValue={dataState.locations.find((l) => l.id === filterState.locationId)}
                    label={t('dashboard:input.location')}
                    keyLabel={'name'}
                    keyEqual={'id'}
                    setFilter={handleChangeLocation}
                    isHaveAllOptions={true}
                    allOptionLabel={t('permits:input.AllLocations')}
                    isLoading={loading.isLoadingLocation}
                    disabled={loading.isLoadingLocation}
                    keyStatus="locationStatus"
                />
            </Grid>

            <Grid item xs={12} container mt={{ xs: 2, sm: 2, md: 2 }} justifyContent={'space-between'}>
                <Grid item xs={12} sm={12} md={6.9} container justifyContent={'space-between'}>
                    <Grid item xs={12} sm={5.9} md={5.9}>
                        <IAutoCompleteRF
                            listData={dataState.companies}
                            itemValue={dataState.companies.find((c) => c.id === filterState.companyId)}
                            label={'Company'}
                            keyLabel={'displayName'}
                            keyEqual={'id'}
                            setFilter={handleChangeCompany}
                            isHaveAllOptions={true}
                            allOptionLabel={'All companies'}
                            isLoading={loading.isLoadingCompany}
                            disabled={loading.isLoadingCompany || selectField === 'location'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5.925} md={5.925} mt={{ xs: 2, sm: 0, md: 0 }}>
                        {/* {regionSelect.RenderSelect} */}
                        <IAutoCompleteRF
                            listData={dataState.regions}
                            itemValue={dataState.regions.find((r) => r.id === filterState.regionId)}
                            label={'Region'}
                            keyLabel={'name'}
                            keyEqual={'id'}
                            setFilter={handleChangeRegion}
                            isHaveAllOptions={true}
                            allOptionLabel="All regions"
                            isLoading={loading.isLoadingRegion}
                            disabled={loading.isLoadingRegion || selectField === 'location' || !filterState.companyId}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={5} mt={{ xs: 2, sm: 2, md: 0 }}>
                    <IMultiSelectWithCheckBoxRF
                        limitTags={3}
                        label={t('dashboard:input.zone')}
                        keyId={'id'}
                        keyLabel={'publicName'}
                        listData={dataState.zones}
                        isDisable={!filterState.locationId}
                        value={getZones()}
                        setFilter={(zones) => {
                            handleChangeZone(zones);
                        }}
                        chipSize={300}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return {
        ...chartData,
        FilterComponents,
        setReportState,
        loading,
        averageLengthOfStay,
        reportState,
        loadingRepeatVisit,
        dataState,
        filterState,
    };
}
