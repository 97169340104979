import { CircularProgress, Grid, Grow, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import color from '../../../../config/Colors';
import { getLabelAllocatedTypeName } from '../../../companyLayout/locationDetail/components/helpers/TenantHelpers';
import { UpsertPermitContext } from '../hooks/useUpsertPermit';

export default function UpsertPermitInfo() {
    const { t, tenantWithZoneAllocated, loadingTenantWithAllocated } = useContext(UpsertPermitContext);

    const tenantWithZoneAllocatedActive = tenantWithZoneAllocated.filter((item) => item.isActive);

    const allocated = tenantWithZoneAllocatedActive.reduce((t, item) => (t += item.amount), 0);
    const used = tenantWithZoneAllocatedActive.reduce((t, item) => (t += item.usedPermit ?? 0), 0);

    return (
        <Stack p={2} sx={{ background: color.grey100 }}>
            {loadingTenantWithAllocated ? (
                <Stack width={'100%'}>
                    <Grow in={true} timeout={Math.min(500, 1000)}>
                        <CircularProgress sx={{ margin: 'auto', color: color.priText }} size={'20px'} />
                    </Grow>
                </Stack>
            ) : (
                <Stack width={'100%'}>
                    <Grow in={true} timeout={Math.min(500, 1000)}>
                        <Stack width={'100%'} spacing={2}>
                            <Stack
                                width={'100%'}
                                sx={{
                                    padding: '16px',
                                    background: color.white,
                                }}
                                justifyContent={'center'}
                                direction="row"
                            >
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    color={color.textPrimary}
                                    component="span"
                                    mx={1}
                                >
                                    {t('permits:text.allocatedPermits')}: {allocated}
                                </Typography>
                                <Typography variant="body1" fontWeight={500} color={color.pink} component="span" mx={1}>
                                    {t('permits:text.usedPermits')}: {used}
                                </Typography>
                            </Stack>
                            <Grid container spacing={1} justifyContent={'center'}>
                                {tenantWithZoneAllocated
                                    .sort((a, b) => {
                                        return a.allocatedPermitType - b.allocatedPermitType;
                                    })
                                    .map((item, index) => {
                                        return (
                                            <Grid key={index} item xs={6} sm={4} md={3} lg={3}>
                                                <Stack
                                                    width={'100%'}
                                                    direction={'column'}
                                                    p={'8px 16px'}
                                                    gap={1}
                                                    sx={{
                                                        borderRadius: 1,
                                                        border: `1px solid ${color.grey300}`,
                                                        background: color.white,
                                                    }}
                                                >
                                                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                                        {getLabelAllocatedTypeName(item.allocatedPermitType)}
                                                    </Typography>
                                                    {item.isActive ? (
                                                        <Typography
                                                            variant="body2"
                                                            color={color.grey600}
                                                            sx={{ textAlign: 'center' }}
                                                        >
                                                            {`Allocated: ${
                                                                item.isUnlimited ? `Unlimited` : item.amount
                                                            } | Used: ${item.usedPermit ?? 0}`}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            color={color.grey600}
                                                            sx={{ textAlign: 'center' }}
                                                        >
                                                            -
                                                        </Typography>
                                                    )}
                                                </Stack>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Stack>
                    </Grow>
                </Stack>
            )}
        </Stack>
    );
}
