/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
    IconButton,
    Stack,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    TooltipProps,
    Typography,
    tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/styles';
import { default as moment, default as momentTimeZone } from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { MdDone } from 'react-icons/md';
import RemoveIcon from '../../../../../assets/images/RemoveIcon';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { SliceLongString } from '../../../../../helpers';
import { formatNumber } from '../../../../../helpers/FormatCurrency';
import useMedia from '../../../../../hooks/useMedia';
import { Pagination } from '../../../../../models/BaseFilter';
import { AllocatedPermitType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { Shop } from '../../../../../models/tenant/Shop';
import IconEdit from '../../../employees/components/IconEdit';
import IconMail from '../../../employees/components/IconMail';
import IconResendMail from '../../../employees/components/IconResendMail';
import { getLabelAllocatedTypeName } from '../helpers/TenantHelpers';

type Props = {
    tenantWithAllocates: Shop[];
    editFunction: (tenant: Shop) => void;
    deleteFunction: (id: number) => void;
    setTenantPaging: React.Dispatch<React.SetStateAction<Pagination<Shop>>>;
    tenantPagination: Pagination<Shop>;
    setTenantWithAllocate: React.Dispatch<React.SetStateAction<Shop[]>>;
    tenantWithAllocate: Shop[];
};

export default function TableRowTenantsInfo(props: Props) {
    const handleEditTenant = (idClient: number) => {
        const tenant = props.tenantPagination.data.find((item) => item.clientId === idClient);
        if (tenant) return props.editFunction(tenant);
    };

    return (
        <TableBody>
            {props.tenantWithAllocates &&
                props.tenantWithAllocates.length > 0 &&
                props.tenantWithAllocates.map((item, index) => {
                    return (
                        <RenderRow
                            tenantPagination={props.tenantPagination}
                            deleteFunction={props.deleteFunction}
                            editFunction={props.editFunction}
                            index={index}
                            item={item}
                            setTenantPaging={props.setTenantPaging}
                            setTenantWithAllocate={props.setTenantWithAllocate}
                            tenantWithAllocate={props.tenantWithAllocate}
                            tenantWithAllocates={props.tenantWithAllocates}
                            key={index}
                            handleEditTenant={handleEditTenant}
                        />
                    );
                })}
        </TableBody>
    );
}

type ChildProps = Omit<Props, ''> & {
    item: Shop;
    index: number;
    tenantWithAllocate: Shop[];
    setTenantWithAllocate: React.Dispatch<React.SetStateAction<Shop[]>>;
    handleEditTenant: (idClient: number) => void;
};

const RenderRow = (props: ChildProps) => {
    const { index, item } = props;
    const { isExtraSmallXs, isMediumMD } = useMedia();
    const { t } = useContext(LanguageContext);
    const isLast = index == props.tenantWithAllocates.length - 1;
    const [isLoadingSendMail, setIsLoadingSendMail] = useState<boolean>(false);

    const handleSendMail = (idClient: number) => {
        setIsLoadingSendMail(true);
        // accountController
        //     .sendMail(item.shopClients?.accountId ?? 0, locationSelect!.name)
        //     .then((res) => {
        //         const pagination = _.cloneDeep(props.tenantPagination);
        //         pagination.data.forEach((data) => {
        //             if (data.clientId === idClient) {
        //                 data.isSendMail = true;
        //             }
        //         });

        //         props.setTenantPaging(pagination);
        //         handleResetDataTenant();
        //         toast.success(t('notification:emailSendSuccess'));
        //     })
        //     .finally(() => setIsLoadingSendMail(false));
    };

    const isMobile = isExtraSmallXs || isMediumMD;

    return (
        <TableRow
            key={index}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                borderBottom: isLast ? `1px solid #ddd` : '',
            }}
        >
            <TableCell component="th" align="left" scope="row">
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>{SliceLongString(item.tenantName, 15)}</Typography>
                    {!!item.firstTimeLoginIhub && (
                        <Tooltip
                            arrow
                            placement="top"
                            title={`Logged in first time at: ${moment(item.firstTimeLoginIhub).format('DD/MM/YYYY')}`}
                        >
                            <Stack
                                sx={{
                                    width: 15,
                                    height: 15,
                                    borderRadius: '100%',
                                    background: (theme) => theme.palette.primary.main,
                                    p: 0.3,
                                    color: '#fff',
                                    cursor: 'pointer',
                                }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <MdDone />
                            </Stack>
                        </Tooltip>
                    )}
                </Stack>
            </TableCell>
            {!isMobile && (
                <TableCell component="th" align="center" scope="row">
                    {SliceLongString(item.managerName, 15)}
                </TableCell>
            )}

            {/* {!isMobile && (
                <TableCell align="center">
                    <Tooltip
                        title={
                            <Typography variant="body2" color={'white'}>
                                {item.email}
                            </Typography>
                        }
                        placement="top"
                    >
                        <Typography variant="body1">{item.email}</Typography>
                    </Tooltip>
                </TableCell>
            )} */}
            <TableCell align={isMobile ? 'center' : 'center'} width={isMobile ? '60%' : '20%'}>
                <Stack>
                    <Tooltip
                        title={
                            <Stack>
                                {item.allocatedTypes
                                    ?.filter((item) => item.isActive)
                                    .sort((a, b) => {
                                        return a.allocatedPermitType - b.allocatedPermitType;
                                    })
                                    .map((item, index) => {
                                        return (
                                            <Typography key={index} variant="body2" color="white">
                                                {index + 1}. Used: {formatNumber.format(item.usedPermit!)}
                                                {![AllocatedPermitType.Once].includes(item.allocatedPermitType)
                                                    ? ` - Allocated: ${item.amount}`
                                                    : ''}
                                            </Typography>
                                        );
                                    })}
                            </Stack>
                        }
                        placement="top"
                        key={index}
                        sx={{ background: 'black' }}
                    >
                        <Stack
                            key={index}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    text: color.secondary,
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {item.allocatedTypes
                                ?.filter((item) => item.isActive)
                                .sort((a, b) => {
                                    return a.allocatedPermitType - b.allocatedPermitType;
                                })
                                .map((item, index) => {
                                    return (
                                        <Typography key={index}>
                                            {index + 1}. {getLabelAllocatedTypeName(item.allocatedPermitType)}
                                            {': '}
                                            {formatNumber.format(item.usedPermit!)}
                                            {![AllocatedPermitType.Once].includes(item.allocatedPermitType)
                                                ? `(${item.amount})`
                                                : ''}
                                        </Typography>
                                    );
                                })}
                        </Stack>
                    </Tooltip>
                </Stack>
            </TableCell>
            <TableCell align="center">
                <Typography variant="body1">{item.isLockScreenPIN ? item.pin ?? '-' : '-'}</Typography>
            </TableCell>
            <TableCell align="center" width={isMobile ? '10%' : '10%'}>
                <Stack flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" gap={0}>
                    <Tooltip
                        title={TooltipTypo(
                            item.isSendMail ? (
                                <Stack>
                                    <Typography sx={{ color: '#fff' }} fontSize={12}>{`${t('action:reSendEmail')} (${
                                        item.emailCount
                                    })`}</Typography>
                                    {!!item.lastEmailSentTime && (
                                        <Typography sx={{ color: '#fff' }} fontSize={12}>{`Last time: ${momentTimeZone
                                            .tz(item.lastEmailSentTime, 'Europe/London')
                                            .fromNow()}`}</Typography>
                                    )}
                                </Stack>
                            ) : (
                                t('action:sendEmail')
                            )
                        )}
                        placement="top"
                    >
                        <span>
                            <IconButton
                                onClick={() => handleSendMail(item.clientId!)}
                                aria-label="fingerprint"
                                color="success"
                                sx={{
                                    backgroundColor: color.grey200,
                                    '&:hover': {
                                        backgroundColor: color.grey200,
                                    },
                                    border: `5px solid ${color.grey100}`,
                                    width: '40px',
                                    height: '40px',
                                    marginLeft: isExtraSmallXs || isMediumMD ? 'auto' : '',
                                    '& .Mui-disabled': {
                                        background: color.grey400,
                                    },
                                }}
                                disabled={isLoadingSendMail}
                            >
                                {item.isSendMail ? (
                                    <IconResendMail isDisable={isLoadingSendMail} />
                                ) : (
                                    <IconMail isDisable={isLoadingSendMail} />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={TooltipTypo(t('action:edit'))} placement="top">
                        <span>
                            <IconButton
                                onClick={() => props.handleEditTenant(item.clientId!)}
                                aria-label="fingerprint"
                                color="success"
                                sx={{
                                    backgroundColor: color.grey200,
                                    '&:hover': {
                                        backgroundColor: color.grey200,
                                    },
                                    border: `5px solid ${color.grey100}`,
                                    width: '40px',
                                    height: '40px',
                                    marginLeft: isMobile ? 'auto' : '',
                                }}
                            >
                                <IconEdit />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <DangerTooltip title={TooltipTypo(t('action:delete'))} placement="top">
                        <span>
                            <IconButton
                                onClick={() => props.deleteFunction(item.clientId!)}
                                aria-label="fingerprint"
                                color="success"
                                sx={{
                                    backgroundColor: color.grey200,
                                    '& .svg-icon-stroke': {
                                        stroke: color.danger,
                                    },
                                    '&:hover': {
                                        backgroundColor: color.grey200,
                                    },
                                    border: `5px solid ${color.grey100}`,
                                    width: '40px',
                                    height: '40px',
                                    marginLeft: isMobile ? 'auto' : '',
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </span>
                    </DangerTooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export const TooltipTypo = (children: React.ReactNode) => {
    return (
        <Typography fontSize={{ xs: '10px', sm: '12px' }} fontWeight={500} color={color.white}>
            {children}
        </Typography>
    );
};

export const DangerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.danger,
        color: color.white,
    },
}));
