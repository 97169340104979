import { useContext } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { LanguageContext } from '../contexts/useLanguage';

export default function useYupSchema() {
    const { t } = useContext(LanguageContext);

    const UpsertPermitSchema = Yup.object().shape({
        // permitType: Yup.string().required(`${' '}`),
        vrnNumber: Yup.string().required(t('validation:thisFieldIsRequired')),
        validateToType: Yup.number(),
        validateFrom: Yup.date()
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        validateTo: Yup.date()
            .nullable()
            .when('validateToType', {
                is: 0,
                then: Yup.date()
                    .required(t('validation:thisFieldIsRequired'))
                    // .min(Yup.ref('validateFrom'), t('validation:endDateMustBeGreaterThanOrEqualToTheStartDate'))
                    .typeError(t('validation:thisFieldIsRequired')),
            }),
        fromHour: Yup.date()
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        toHour: Yup.date()
            .when('fromHour', (fromHour, schema) => {
                if (moment(fromHour, 'YYYY-MM-DD').isValid()) {
                    const fromTime = new Date(fromHour.getTime());
                    const validateTime = moment(fromTime).add(1, 'minutes').toDate();
                    return schema
                        .typeError(t('validation:thisFieldIsRequired'))
                        .min(validateTime, t('validation:endTimeMustBeGreaterThanStartTime'))
                        .required(t('validation:thisFieldIsRequired'));
                } else {
                    return schema;
                }
            })
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        bayNumber: Yup.string().max(40, 'Bay information must be less than 40 characters'),
    });

    const yupValidate = {
        vrnNumber: Yup.string().required(t('validation:thisFieldIsRequired')),
        validateToType: Yup.number(),
        validateFrom: Yup.date()
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        validateTo: Yup.date()
            .nullable()
            .when('validateToType', {
                is: 0,
                then: Yup.date()
                    .required(t('validation:thisFieldIsRequired'))
                    // .min(Yup.ref('validateFrom'), t('validation:endDateMustBeGreaterThanOrEqualToTheStartDate'))
                    .typeError(t('validation:thisFieldIsRequired')),
            }),
        fromHour: Yup.date()
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        toHour: Yup.date()
            .when('fromHour', (fromHour, schema) => {
                if (moment(fromHour, 'YYYY-MM-DD').isValid()) {
                    const fromTime = new Date(fromHour.getTime());
                    const validateTime = moment(fromTime).add(1, 'minutes').toDate();
                    return schema
                        .typeError(t('validation:thisFieldIsRequired'))
                        .min(validateTime, t('validation:endTimeMustBeGreaterThanStartTime'))
                        .required(t('validation:thisFieldIsRequired'));
                } else {
                    return schema;
                }
            })
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        // bayNumber: Yup.string().max(40, 'Bay information must be less than 40 characters'),
    };

    const yupValidateUser = Yup.object({
        firstName: Yup.string().required(t('validation:firstNameIsRequired')),
        lastName: Yup.string().required(t('validation:lastNameIsRequired')),
        email: Yup.string()
            .email(`${t('validation:invalidEmailFormat')}`)
            .required(`${t('validation:emailIsRequired')}`),
        phoneNumber: Yup.string(),
    });

    const yupValidateShop = Yup.object({
        tenantName: Yup.string().required(t('validation:tenantNameIsRequired')),
        managerName: Yup.string().required(t('validation:managerNameIsRequired')),
        bayInfo: Yup.string().max(40, 'Bay information must be less than 40 characters'),
        allocatedTypes: Yup.array().of(
            Yup.object().shape({
                isActive: Yup.boolean().transform((value) => !!value),
                allocatedPermitType: Yup.number(),
                usedPermit: Yup.number(),
                isValid: Yup.boolean().required(),
            })
        ),
    });
    return { UpsertPermitSchema, yupValidate, yupValidateUser, yupValidateShop };
}
