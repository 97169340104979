import { Stack, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { default as moment, default as momentTimeZone } from 'moment-timezone';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { MdDone } from 'react-icons/md';
import RemoveIcon from '../../../../../../assets/images/RemoveIcon';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { accountController } from '../../../../../../controllers';
import { SliceLongString } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/moment';
import { LocationsContext } from '../../../../../../hooks/useLocations';
import { TenantContext } from '../../../../../../hooks/useTenants';
import { ClientLoginApplication, Shop, ShopClient } from '../../../../../../models/tenant/Shop';
import MyTable, { TableHeadContent } from '../../../../../MyTable';
import IconEdit from '../../../../employees/components/IconEdit';
import IconMail from '../../../../employees/components/IconMail';
import IconResendMail from '../../../../employees/components/IconResendMail';

type Props = {
    shop: Shop;
    isShowAction?: boolean;
};

export default function ShopUserTableItem(props: Props) {
    const shopClients = props.shop.shopClients ?? [];
    const largeHead: TableHeadContent[] = [
        {
            label: '',
            alight: 'left',
        },
        {
            label: 'User',
            alight: 'center',
        },
        {
            label: 'Email contact',
            alight: 'center',
        },
        {
            label: 'Phone number',
            alight: 'center',
        },
        {
            label: 'Created at',
            alight: 'center',
        },
        {
            label: '',
            alight: 'center',
        },
    ];
    return (
        <MyTable
            tableHead={largeHead}
            tableRow={
                <TableBody>
                    {shopClients.map((item, index) => {
                        const isLast = index === shopClients.length - 1;
                        return (
                            <TableRowItem
                                key={index}
                                shopClient={item}
                                index={index}
                                isLast={isLast}
                                shop={props.shop}
                                isShowAction={props.isShowAction}
                            />
                        );
                    })}
                </TableBody>
            }
            containerProps={{ padding: 0 }}
            headerProps={{ fontWeight: 400 }}
        />
    );
}

type RowProps = {
    shopClient: ShopClient;
    index: number;
    isLast?: boolean;
    shop: Shop;
    isShowAction?: boolean;
};

export const TableRowItem = ({ index, shopClient, isLast, shop, isShowAction }: RowProps) => {
    const { t } = useContext(LanguageContext);
    const client = shopClient.client!;
    const [isLoadingSendMail, setIsLoadingSendMail] = useState<boolean>(false);
    const { locationSelect } = useContext(LocationsContext);

    const { tenantPaging, setTenantPaging, setShopSelect, userFormData, modalUpsertUser, handleDeleteUser } =
        useContext(TenantContext);

    const handleSendMail = (accountId: number) => {
        setIsLoadingSendMail(true);
        accountController
            .sendMail(shopClient.client?.accountId ?? 0, locationSelect!.name)
            .then(() => {
                const pagination = _.cloneDeep(tenantPaging);
                pagination.data.forEach((shop) => {
                    shop.shopClients?.forEach((sc) => {
                        if (sc.client?.accountId === shopClient.client?.accountId) {
                            sc.client!.userAccount!.isSendMail = true;
                            sc.client!.userAccount!.emailCount = (sc.client!.userAccount!.emailCount ?? 0) + 1;
                            sc.client!.userAccount!.lastEmailSentTime = new Date();
                        }
                    });
                });
                setTenantPaging(pagination);
                // handleResetDataTenant();
                toast.success(t('notification:emailSendSuccess'));
            })
            .finally(() => setIsLoadingSendMail(false));
    };

    const handleEditUser = () => {
        setShopSelect(shop);
        const isEnableITicketLite = client.appLogins?.some((a) => a.application === ClientLoginApplication.iTicketLite);
        userFormData.reset({ ...client, isEnableITicketLite });
        modalUpsertUser.handleOpen();
    };

    return (
        <TableRow
            key={index}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                borderBottom: isLast ? `1px solid #ddd` : '',
            }}
        >
            <TableCell component="th" align="center" scope="row">
                <Typography>{index + 1}</Typography>
            </TableCell>

            <TableCell component="th" align="center" scope="row">
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>{SliceLongString([client.firstName, client.lastName].join(' '), 15)}</Typography>
                    {!!client.userAccount!.firstTimeLoginIhub && (
                        <Tooltip
                            arrow
                            placement="top"
                            title={`Logged in first time at: ${moment(client.userAccount!.firstTimeLoginIhub).format(
                                'DD/MM/YYYY'
                            )}`}
                        >
                            <Stack
                                sx={{
                                    width: 15,
                                    height: 15,
                                    borderRadius: '100%',
                                    background: (theme) => theme.palette.primary.main,
                                    p: 0.3,
                                    color: '#fff',
                                    cursor: 'pointer',
                                }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <MdDone />
                            </Stack>
                        </Tooltip>
                    )}
                </Stack>
            </TableCell>

            <TableCell align="center">
                <Tooltip
                    title={
                        <Typography variant="body2" color={'white'}>
                            {client.email}
                        </Typography>
                    }
                    placement="top"
                >
                    <Typography variant="body1">{client.email}</Typography>
                </Tooltip>
            </TableCell>

            <TableCell align="center">
                <Typography variant="body1">{client.phoneNumber}</Typography>
            </TableCell>

            <TableCell align="center">
                <Typography variant="body1">{formatDate.getLocalDate(client.created!)}</Typography>
            </TableCell>

            {isShowAction && (
                <TableCell align="center">
                    <Stack flexDirection={'row'} justifyContent="end" gap={0.5}>
                        <IconButtonWithTooltip
                            function={() => handleSendMail(client.id!)}
                            tooltipTitle={
                                client.userAccount!.isSendMail ? (
                                    <Stack>
                                        <Typography sx={{ color: '#fff' }} fontSize={12}>{`${t(
                                            'action:reSendEmail'
                                        )} (${client.userAccount!.emailCount})`}</Typography>
                                        {!!client.userAccount!.lastEmailSentTime && (
                                            <Typography
                                                sx={{ color: '#fff' }}
                                                fontSize={12}
                                            >{`Last time: ${momentTimeZone
                                                .tz(client.userAccount!.lastEmailSentTime, 'Europe/London')
                                                .fromNow()}`}</Typography>
                                        )}
                                    </Stack>
                                ) : (
                                    t('action:sendEmail')
                                )
                            }
                            icon={
                                client.userAccount!.isSendMail ? (
                                    <IconResendMail isDisable={isLoadingSendMail} />
                                ) : (
                                    <IconMail isDisable={isLoadingSendMail} />
                                )
                            }
                        />

                        <IconButtonWithTooltip
                            function={handleEditUser}
                            tooltipTitle={TooltipTypo(t('action:edit'))}
                            icon={<IconEdit />}
                        />

                        <IconButtonWithTooltip
                            function={() => handleDeleteUser(client.id!)}
                            tooltipTitle={TooltipTypo(t('action:delete'))}
                            icon={<RemoveIcon />}
                            type="danger"
                        />
                    </Stack>
                </TableCell>
            )}
        </TableRow>
    );
};

const TooltipTypo = (children: React.ReactNode) => {
    return (
        <Typography fontSize={{ xs: '10px', sm: '12px' }} fontWeight={500} color={color.white}>
            {children}
        </Typography>
    );
};
