import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { FilterTenant as FilterShop, TenantsPagination as ShopsPagination } from '../models/BaseFilter';
import { LocationWithAllZone } from '../models/Location';
import { AllocatedType } from '../models/tenant/AllocatedTypeWithAmount';
import { Shop } from '../models/tenant/Shop';

export class ShopController extends BaseHttpController<Shop> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Shop', client);
    }

    async filter(filter: FilterShop): Promise<ShopsPagination> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }

    async delete(id: string) {
        const res = await this.client.delete(`${this.serviceURL}/${this.basePath}/${id}`);
        return res.data;
    }

    async getShopAllocated(props: ShopAllocatedProps): Promise<AllocatedType[]> {
        const res = await this.doPost({ path: `getShopAllocated`, body: props, config: {} });
        return res.data;
    }

    async getLocationByShop(id: number): Promise<LocationWithAllZone> {
        const res = await this.doGet({ path: `GetLocationByShopId/${id}`, config: {} });
        return res.data;
    }

    async getShopByZoneIds(props: number[]): Promise<Shop[]> {
        const res = await this.doPost({ path: `getShopByZoneIds`, body: { zoneIds: props }, config: {} });
        return res.data;
    }
}

export interface TenantWithZoneAllocated {
    allocated: number;
    used: number;
}

export interface ShopAllocatedProps {
    shopId: number;
    // zoneId: number
}
