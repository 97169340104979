import React from 'react';

export default function ICAddTenant() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path
                d="M6.07453 9.65369C5.03804 9.04694 4.34162 7.92195 4.34162 6.63624V3.99378C4.34162 2.06521 5.90683 0.5 7.83539 0.5C9.76396 0.5 11.3292 2.06521 11.3292 3.99378V6.63624C11.3292 7.92195 10.6327 9.04694 9.59626 9.65369V10.5807C9.68642 10.5809 9.77606 10.5834 9.86511 10.5882C8.62245 11.6561 7.83539 13.2392 7.83539 15.0062C7.83539 15.8343 8.00826 16.6221 8.31989 17.3353H2.01243C1.36958 17.3353 0.847839 16.8136 0.847839 16.1708V15.8214C0.847839 12.9332 3.18867 10.5877 6.07453 10.5807V9.65369Z"
                fill="#2E7D32"
            />
            <path
                d="M13.0761 18.5H14.2406C14.5621 18.5 14.8229 18.2391 14.8229 17.9177V16.1708H16.5698C16.8913 16.1708 17.1521 15.9099 17.1521 15.5885V14.4239C17.1521 14.1025 16.8913 13.8416 16.5698 13.8416H14.8229V12.0947C14.8229 11.7733 14.5621 11.5124 14.2406 11.5124H13.0761C12.7546 11.5124 12.4938 11.7733 12.4938 12.0947V13.8416H10.7469C10.4254 13.8416 10.1646 14.1025 10.1646 14.4239V15.5885C10.1646 15.9099 10.4254 16.1708 10.7469 16.1708H12.4938V17.9177C12.4938 18.2391 12.7546 18.5 13.0761 18.5Z"
                fill="#2E7D32"
            />
        </svg>
    );
}
