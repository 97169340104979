import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { LocationsContext } from '../../../../../hooks/useLocations';
import ViewMapItem from '../../../locations/components/ViewMapItem';

export default function GeneralInformation() {
    const { t } = useContext(LanguageContext);
    const { locationSelect } = useContext(LocationsContext);

    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const generalInformation = [
        {
            label: t('companies:text.name'),
            value: locationSelect!.name,
        },
        {
            label: t('companies:text.address'),
            value: locationSelect!.address1,
        },
        {
            label: t('companies:text.postcode'),
            value: locationSelect!.postcode,
        },
        {
            label: t('companies:text.latitude'),
            value: locationSelect!.latitude,
        },
        {
            label: t('companies:text.longitude'),
            value: locationSelect!.longitude,
        },
    ];

    return (
        <Grid item container xs={12} padding={2}>
            <Grid container direction={'row'} justifyContent={'center'} spacing={2}>
                <Grid item container xs={12} md={5} direction={'column'}>
                    {generalInformation.map((item, index) => (
                        <Grid
                            key={index}
                            py={1}
                            item
                            container
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            sx={{ borderBottom: '1.5px solid #dddddd' }}
                        >
                            <Grid item xs={4}>
                                <Typography variant="body1">{item.label}</Typography>
                            </Grid>

                            <Grid item xs={8}>
                                <Typography
                                    variant="body1"
                                    textAlign={'right'}
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                >
                                    {item.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid item container xs={12} md={7}>
                    {locationSelect ? (
                        <ViewMapItem isShowLocationPopOver={false} locations={[locationSelect!]} />
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
