import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LanguageContext } from '../../../contexts/useLanguage';
import { Grid, IconButton } from '@mui/material';
import useMedia from '../../../hooks/useMedia';
import CloseIcon from '../../../assets/svg/CloseIcon';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 350,
        md: 500,
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    py: 5,
    px: 3,
    borderRadius: '10px',
};
type Props = {
    onClose: () => void;
    open: boolean;
    onConfirm: () => void;
};
export default function ModalConfirmExport(props: Props) {
    const { onClose, open, onConfirm } = props;
    const { t } = React.useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => onClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 16,
                                top: 16,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography textAlign={'center'} variant="h4">
                            {' '}
                            Notification
                        </Typography>
                        <Typography mt={isMobileSM ? 2 : 2} textAlign={'center'} >
                            {' '}
                            The export file you have requested will be sent to your e-mail. Please allow up to 5 minutes
                            for it to arrive in your inbox
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
