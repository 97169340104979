import { Box, Grid, Pagination, Stack, Tooltip } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import MyTable from '../../../../MyTable';

import QuestionCircle from '../../../../../assets/svg/QuestionCircleIcon';
import NoDataToDisplay from '../../../../../components/NoDataToDisplay';
import color from '../../../../../config/Colors';
import { LocationsContext } from '../../../../../hooks/useLocations';
import { ReportType } from '../../../../../models/ReportType';
import TableZones from './TableZones/TableZones';

export default function ZonesTab() {
    const { t } = useContext(LanguageContext);
    const { zoneDataState, getLocationDetail } = useContext(LocationsContext);
    const { zonesSelect } = zoneDataState;

    useEffect(() => {
        getLocationDetail();
    }, []);

    return (
        <Grid item container xs={12}>
            {zonesSelect && zonesSelect.length > 0 ? (
                <>
                    <MyTable
                        tableHead={[
                            {
                                label: t('companies:text.zoneName'),
                                alight: 'left',
                            },
                            {
                                label: t('companies:text.type'),
                                alight: 'left',
                            },
                            {
                                label: t('companies:text.serviceType'),
                                alight: 'center',
                            },
                            {
                                label: t('companies:text.totalBays'),
                                alight: 'center',
                            },
                            {
                                label: (
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                        {t('companies:text.totalBaysCount')}
                                        <Tooltip
                                            sx={{
                                                '&:hover': {
                                                    '& .employeeIcon': {
                                                        stroke: color.secondary,
                                                        transition: '0.3s',
                                                    },
                                                },
                                            }}
                                            placement="top"
                                            title={
                                                'Please use this bay count feature to adjust the capacity analysis report to reflect the amount of available bays on that particular zone'
                                            }
                                        >
                                            <Stack alignItems={'center'}>
                                                <QuestionCircle style={{ cursor: 'pointer' }} />
                                            </Stack>
                                        </Tooltip>
                                    </Stack>
                                ),
                                alight: 'center',
                            },
                            {
                                label: <></>,
                                alight: 'center',
                            },
                        ]}
                        reportType={ReportType.dailyVisit}
                        tableRow={<TableZones />}
                    />
                    {/* <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                        <Pagination
                            count={1}
                            page={1}
                            onChange={() => {}}
                            variant="outlined"
                            shape="rounded"
                            sx={{ mt: 3, mb: '40px' }}
                        />{' '}
                    </Stack> */}
                </>
            ) : (
                <Box padding={'16px'} width={'100%'}>
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                </Box>
            )}
        </Grid>
    );
}
