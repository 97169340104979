/* eslint-disable eqeqeq */
import { Box, Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, subDays } from 'date-fns';
import { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CalendarIcon from '../../../../../../assets/svg/CalendarIcon';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { MAXIMUM_RETREAT_DAY, PermitFromData, UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import { RecurProps } from '../../recurringType/DayRecurring';
export const useStylesPicker = makeStyles((theme: Theme) => ({
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
}));

export default function UpsertPermitItemTemporary({ isEdit, parentForm }: RecurProps) {
    const { indexForm } = parentForm;
    const {temporaryDuration} = useContext(UpsertPermitContext);
    const {
        control,
        setValue,
        formState: { errors },
        clearErrors,
        setError,
    } = useFormContext<PermitFromData>();

    const { validateFrom, validateTo, deleted } = useWatch({
        control: control,
        name: `permits.${indexForm}`,
    });

    const classes = useStylesPicker();
    const { t } = useContext(LanguageContext);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container display={'flex'} direction={'row'} spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name={`permits.${indexForm}.validateFrom`}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                // {...field}
                                label={<LabelWithRequired label={t('permits:text.startDate')} />}
                                // value={getValues().validateFrom}
                                value={validateFrom}
                                disabled={isEdit}
                                onChange={(newValue, _) => {
                                    if (!newValue) return;
                                    field.onChange(newValue);
                                    setError(`permits.${indexForm}.validateTo`, {
                                        type: 'required',
                                        message: t('validation:thisFieldIsRequired'),
                                    });
                                    setValue(`permits.${indexForm}.validateTo`, null, { shouldValidate: true });
                                }}
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                OpenPickerButtonProps={{ className: classes.inputStyle }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // {...getFieldProps('validateFrom')}
                                        error={Boolean(error?.message)}
                                        helperText={error?.message}
                                        fullWidth={true}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                renderDay={(day, _value, DayComponentProps) => {
                                    return (
                                        <Box className={classes.inputStyle} key={JSON.stringify(day)}>
                                            <PickersDay {...DayComponentProps} />
                                        </Box>
                                    );
                                }}
                                inputFormat="dd/MM/yyyy"
                                minDate={subDays(new Date(), MAXIMUM_RETREAT_DAY)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name={`permits.${indexForm}.validateTo`}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker
                                // {...field}
                                label={<LabelWithRequired label={t('permits:text.endDate')} />}
                                // value={getValues().validateFrom}
                                value={validateTo}
                                disabled={validateFrom == null || !!deleted}
                                onChange={(newValue, _) => {
                                    if (!newValue) return;
                                    field.onChange(newValue);
                                }}
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                OpenPickerButtonProps={{ className: classes.inputStyle }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // {...getFieldProps('validateFrom')}
                                        error={Boolean(error?.message)}
                                        helperText={error?.message}
                                        fullWidth={true}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                renderDay={(day, _value, DayComponentProps) => {
                                    return (
                                        <Box className={classes.inputStyle} key={JSON.stringify(day)}>
                                            <PickersDay {...DayComponentProps} />
                                        </Box>
                                    );
                                }}
                                inputFormat="dd/MM/yyyy"
                                minDate={isEdit ? new Date() : new Date(validateFrom!)}
                                maxDate={addDays(new Date(validateFrom!), temporaryDuration)}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
