import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import IOSSwitch from '../../../../../../components/IOSSwitch';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { Client } from '../../../../../../models/tenant/Shop';

type Props = {
    control?: any;
    formData: UserFormData;
};

export type UserFormData = UseFormReturn<Client, any, undefined>;

export default function UpsertUserOfTenant({ formData }: Props) {
    const { t } = useContext(LanguageContext);

    const {
        control,
        formState: { errors, isValid, isDirty },
        setError,
        watch,
    } = formData;
    const isEdit = watch().id !== 0;

    return (
        <Grid
            item
            container
            spacing={2}
            sx={{
                '& .MuiFormHelperText-root': {
                    fontSize: '12px',
                },
            }}
        >
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name={'firstName'}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            error={!!error?.message}
                            helperText={error?.message}
                            fullWidth
                            label={"First Name"}
                            placeholder={'Enter first name'}
                            onChange={(e) => {
                                field.onChange(e);
                            }}
                            inputProps={{
                                maxLength: 50,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name={'lastName'}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            error={!!error?.message}
                            helperText={error?.message}
                            fullWidth
                            label={'Last Name'}
                            placeholder={'Enter last name'}
                            onChange={(e) => {
                                field.onChange(e);
                            }}
                            inputProps={{
                                maxLength: 50,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name={'email'}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            error={!!error?.message}
                            helperText={error?.message}
                            fullWidth
                            disabled={isEdit}
                            label={<LabelWithRequired label={'Email'} />}
                            inputProps={{
                                maxLength: 320,
                            }}
                            placeholder={'Enter email'}
                            onChange={(e) => {
                                field.onChange(e);
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name={'phoneNumber'}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            error={!!error?.message}
                            helperText={error?.message}
                            fullWidth
                            label={t('companies:text.phoneNumber')}
                            placeholder={t('input:enterContactNumber')}
                            onChange={(e) => {
                                field.onChange(e);
                            }}
                            inputProps={{
                                maxLength: 11,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                    <Box mr={4}>
                        <Typography>iTicket lite service</Typography>
                    </Box>
                    <Controller
                        name="isEnableITicketLite"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <IOSSwitch
                                {...field}
                                label={
                                    <Typography sx={{ minWidth: '55px' }}>
                                        {!!field.value ? 'Enable' : 'Disable'}
                                    </Typography>
                                }
                                iosSwitchProps={{
                                    checked: field.value,
                                    onChange(event, checked) {
                                        field.onChange(event);
                                        // setValue('pin', 0);
                                    },
                                }}
                            />
                        )}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
}
