import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Pagination, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { ArrayPagination } from '../../../../helpers';
import { RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import TableUploadPermit from '../../uploadPermits/components/TableUploadPermit';
import { CheckingVRNItem } from '../hooks/useUpsertPermit';
import TableRowUpsertVRNChecking from './TableRowUpsertVRNChecking';

type Props = {
    handleClose: () => void;
    vrnCheckingList: CheckingVRNItem[];
    handleConFirm: () => void;
};

interface State {
    page: number;
    perPage: number;
    total: number;
    totalPage: number;
    data: CheckingVRNItem[];
}

const ItemPerPage = 5;

export default function ModalCheckVRNUpsert({ handleClose, vrnCheckingList, handleConFirm }: Props) {
    const { t } = useContext(LanguageContext);

    const [state, setState] = useState<State>(ArrayPagination(vrnCheckingList, 1, ItemPerPage));

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setState(ArrayPagination(vrnCheckingList, value, ItemPerPage));
    };

    const getInvalid = (CheckingVRNItem: CheckingVRNItem[]) => {
        if (CheckingVRNItem.length > 0) {
            return vrnCheckingList.reduce(
                (total, item) => (item.status == RawUploadPermitStatus.InValid ? (total += 1) : (total += 0)),
                0
            );
        }
        return 0;
    };

    const isHaveValid = getInvalid(vrnCheckingList) !== vrnCheckingList.length;

    return (
        <Box pb={2} pt={3}>
            <Typography variant="h3" align="center" sx={{}} color={color.danger}>
                {t('permits:upload.warning')}
            </Typography>

            {isHaveValid ? (
                <Typography variant="body1" align="center" mt={2}>
                    {t('permits:upload.itFoundXInvalidPermitSDoYouStillWishToSaveYValidPermitS')}
                </Typography>
            ) : (
                <Typography variant="body1" align="center" mt={2}>
                    {t('permits:upload.weFoundSomeErrors')}
                </Typography>
            )}

            <TableUploadPermit
                tableHead={[
                    {
                        label: t('permits:text.permit'),
                        alight: 'center',
                    },
                    {
                        label: t('permits:upload.VRN'),
                        alight: 'center',
                    },
                    {
                        label: t('permits:upload.status'),
                        alight: 'center',
                    },
                    {
                        label: t('permits:upload.reason'),
                        alight: 'center',
                    },
                ]}
                tableRow={<TableRowUpsertVRNChecking vrnCheckingList={state.data} />}
            />
            <Grid
                container
                display={'flex'}
                justifyContent={'center'}
                sx={{ display: vrnCheckingList.length > ItemPerPage ? 'block' : 'none' }}
            >
                <Pagination
                    count={state.totalPage}
                    page={state.page}
                    onChange={handleChangePagination}
                    variant="outlined"
                    shape="rounded"
                    sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}
                />{' '}
            </Grid>

            <Grid px={2}>
                <Typography variant="body1"></Typography>
            </Grid>

            {isHaveValid ? (
                <Grid display={'flex'} flexDirection={'row'} justifyContent="space-between" px={2}>
                    <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={handleClose}>
                        {t('action:cancel')}
                    </Button>
                    <LoadingButton
                        loading={false}
                        startIcon={<></>}
                        loadingPosition="start"
                        variant="errorOutlined"
                        disabled={!isHaveValid}
                        sx={{ minWidth: '150px' }}
                        type="submit"
                        onClick={handleConFirm}
                    >
                        {t('action:save')}
                    </LoadingButton>
                </Grid>
            ) : (
                <Grid display={'flex'} flexDirection={'row'} justifyContent={'end'} px={2}>
                    <LoadingButton
                        loading={false}
                        startIcon={<></>}
                        loadingPosition="start"
                        variant="errorOutlined"
                        sx={{ minWidth: '150px' }}
                        type="submit"
                        onClick={handleClose}
                    >
                        OK
                    </LoadingButton>
                </Grid>
            )}
        </Box>
    );
}
